import Vue from 'vue';
import VueRouter from 'vue-router';
import middlewares from './middlewares';
import $ from 'jquery';
import pages from '../config/pages';

const RouteProvider = {
  interval: null,
  getRoute(routeName) {
    return pages[routeName];
  },
  getRoutes(namespace) {
    const routes = [];

    for ( let pageName in pages ) {
      let page = pages[pageName];
      if (page.route.parent && page.route.parent === namespace) {
        routes.push(page.route);
      }
    }

    return routes;
  },
  isStoreReady() {
    return router.app.$store?.state?.session?.ready;
  },
  processRoute(to, from, next) {

    $('.pre-loader').addClass('hide');

    /*
    --------------------------------------------------------------------
    | Route Security
    --------------------------------------------------------------------
    */
    // Check If Potential Route Requires A Middleware
    // if (to.meta.middleware) {
    //   // Get Response From Middleware
    //   const redirect = runMiddlewares(to, from, next,  to.meta.middleware);

    //   // Allow Access If Middleware Returns True
    //   if (redirect !== true) {
    //     return false;
    //   }

    // }

    const route = this.getRoute(to.name);

    const middlewares = ['downloadApp', ...route.middlewares, 'account_type', 'permissions'];

    // console.log(middlewares);

    const redirect = this.runMiddlewares(to, from, next, middlewares);

    if (redirect !== true) {
      return next(false);
    }

    /*
    --------------------------------------------------------------------
    | Navigate To Next Route
    --------------------------------------------------------------------
    */
    next();

    router.app.$emit('global', { event: 'navigate', params: [to, from] });

  },
  runMiddlewares(to, from, next, middleware) {
    // Check if provided value is an array of middlewares
    if (middleware && middleware.constructor.toString().match(/array/i)) {
      // Loop through all specified middlewares
      var allowed = middleware.every( mdw => {
        // Run middleware if the middleware exists and return allow access if not
        return middlewares[mdw] ? middlewares[mdw].apply(router.app, [to, from, next]) : true;
      } );

      // Return Access Response From All Middlewares
      return allowed;
    }
    // Return Access Response From Middleware
    return middlewares[middleware] ? middlewares[middleware].apply(router.app, [to, from, next]) : true;
  }

}

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    ...RouteProvider.getRoutes('root'),
    {
      path: '/',
      component: require('@/views/auth/Layout.vue').default,
      children: [
        ...RouteProvider.getRoutes('auth'),
      ]
    },

    {
      path: '/',
      component: require('@/views/app/Layout.vue').default,
      children: [
        ...RouteProvider.getRoutes('app'),
      ]
    },

    ...RouteProvider.getRoutes('register'),
    ...RouteProvider.getRoutes('errors'),

  ]
})

export default router;


router.beforeEach((to, from, next) => {

  /*
  --------------------------------------------------------------------
  | Wait For Store To Be 100% Ready
  --------------------------------------------------------------------
  */

  // Wait For Store To Be Mounted And Session Restoration to be Completed/Attempted
  // while (!router.app.$store || !router.app.$store.state.session.ready) {

  //   // Check Again in 0.01 Seconds
  //   await new Promise(resolve => setTimeout( resolve, 10 ) );

  // }

  RouteProvider.interval = setInterval(() => {
    if (RouteProvider.isStoreReady()) {
      clearInterval(RouteProvider.interval);
      RouteProvider.processRoute(to, from, next);
    }
  }, 10);
});


router.afterEach(to => {
  document.title = to.meta.title;
  if (router.app.sidebar.open) {
    router.app.closeSidebar();
  }
});

router.onError(error => {
  console.log('A Routing Error Occured')
  console.log([error]);
});