import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    design: require('./design').default,
    modals: require('./modals').default,
    resources: require('./resources').default,
    session: require('./session').default,
    notifications: require('./notifications').default,
    details: require('./details').default
  },
  state: {
    /*
    ------------------------------------------------------------------
    Storage Method
    ------------------------------------------------------------------
    */
    store_type: 'localStorage',
    loanbotToken: 'a65b49e29fd4f256ba15568de0b0c5f37ff596b2',

    /*
    ------------------------------------------------------------------
    All Banks
    ------------------------------------------------------------------
    */
    mbsAllowedBanks: require('@/assets/json/mbs-allowed-banks.json'),
    mbsBanks: require('@/assets/json/mbs-banks.json'),
    okraBanks: require('@/assets/json/okra-banks.json'),
    banks: require('@/assets/json/banks.json'),
    states: require('@/assets/json/states.json'),
    industries: require('@/assets/json/industries.json'),
  },
});
