<template>
	<div class="modal" :class="{open: isOpen}"  @click="clickedModal">
		<div class="modal-content" :class="className" :style="contentStyle">

			<slot name="modal-close">
				<button class="modal-close" @click.prevent="close">
					<ion-icon name="close"></ion-icon>
				</button>
			</slot>
			
			<slot />
			
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			className: {
				type: [Array, Object, String],
				default: '',
			},
			contentStyle: {
				type: [Array, Object, String],
			},
		},
		data() {
			return {
				isOpen: false
			}
		},
		mounted() {
			this.$emit('mounted');
		},
		methods: {
			clickedModal(event) {
				if (event.target.matches('.modal')) {
					this.close();
				}
			},
			close() {
				this.isOpen = false;
				this.$emit('close');
			},
			open() {
				this.isOpen = true;
				this.$emit('open');
			}
		}
	}
</script>