export default {
  loggedIn(user) {
    this.initializeCustomerChannel(user.id);
    window.dengage('setContactKey', user.email);
    // console.log(user);
  },
  sessionUpdate(user) {
    this.initializeCustomerChannel(user.id);
  },
  navigate(to) {
    const accountType = to.params.accountType

    if (accountType) {
      this.$store.dispatch('resources/fetchAll', accountType);
    }

  }
}
