<template>
  <div>
    <template>
      <div class="nav-title">Account</div>
      <ul class="nav">
        <li class="nav-item">
          <router-link
            :to="{ name: 'dashboard' }"
            class="flex flex-row items-center text-credpal-400 text-xs"
          >
            <img :src="dashboard" alt="icon" class="mr-4" />
            <span class="text-credpal-400">Dashboard</span>
          </router-link>
        </li>
        <!-- <li class="nav-item">
                    <router-link :to="{ name: 'activities' }" class="flex flex-row items-center text-xs">
                        <ion-icon name="alarm-outline" class="text-12 mr-4" />
                        <span>All Activities</span>
                    </router-link>
                </li> -->
        <li class="nav-item">
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.marketplace)"
          >
            <ion-icon name="cart-outline" class="text-black text-16 mr-4" />
            <span>Marketplace & Bills</span>
          </a>
          <ul class="sub-nav collapse" ref="marketplace">
            <li class="nav-item">
              <router-link
                :to="{ name: 'bills' }"
                class="flex flex-row items-center text-xs opacity-75"
              >
                <span class="text-credpal-400">Bills</span>
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="canAccess('marketplace') && staffAccountType === 'personal'"
            >
              <router-link
                :to="{ name: 'marketplace' }"
                class="flex flex-row items-center text-xs opacity-75"
              >
                <span class="text-credpal-400 inline-flex w-full"
                  >Marketplace
                  <span class="flex h-3 w-3">
                    <span
                      class="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-blue-400 opacity-75"
                    ></span>
                    <span
                      class="relative inline-flex rounded-full h-3 w-3 bg-blue-500"
                    ></span>
                  </span>
                </span>
              </router-link>
            </li>
            <li class="nav-item" v-if="canAccess('order-history') && staffAccountType === 'personal'">
              <router-link
                :to="{ name: 'order-history' }"
                class="flex flex-row items-center text-xs opacity-75"
              >
                <span class="text-credpal-400">Order History</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.loans)"
          >
            <img :src="history" alt="icon" class="mr-4" />
            <span>Loans</span>
          </a>
          <ul class="sub-nav collapse" ref="loans">
            <li
              class="nav-item"
              v-if="canAccess('repayments') && staffAccountType === 'personal'"
            >
              <router-link
                :to="{ name: 'repayments' }"
                class="flex flex-row items-center text-xs opacity-75"
              >
                <!-- <img :src="loan" alt="icon" class="mr-4" /> -->
                <span class="text-credpal-400">Loan Repayments</span>
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="canAccess('loans') && staffAccountType === 'personal'"
            >
              <router-link
                :to="{ name: 'loans' }"
                class="flex flex-row items-center text-xs opacity-75"
              >
                <!-- <img :src="history" alt="icon" class="mr-4" /> -->
                <span class="text-credpal-400">Loan History</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li
          class="nav-item"
          v-if="canAccess('transactions') && staffAccountType === 'personal'"
        >
          <router-link
            :to="{ name: 'transactions' }"
            class="flex flex-row items-center text-xs opacity-75"
          >
            <ion-icon
              name="document-text-outline"
              class="text-16 text-credpal-400 mr-4"
            />
            <span>Transactions</span>
          </router-link>
        </li>
        <li
          class="nav-item"
          v-if="canAccess('expenses') && staffAccountType === 'business' && 0"
        >
          <router-link
            :to="{ name: 'expenses' }"
            class="flex flex-row items-center text-xs opacity-75"
          >
            <ion-icon
              name="document-text-outline"
              class="text-12 text-credpal-400 mr-4"
            />
            <span>Expenses</span>
          </router-link>
        </li>
        <li
          class="nav-item"
          v-if="canAccess('expense-requests') && staffAccountType === 'business'"
        >
          <router-link
            :to="{ name: 'expense-requests' }"
            class="flex flex-row items-center text-xs opacity-75"
          >
            <ion-icon name="cash-outline" class="text-12 mr-4" />
            <span>Expense Requests</span>
          </router-link>
        </li>
        <li class="nav-item">
          <a
            href="#!"
            class="flex flex-row items-center text-xs"
            @click.prevent="collapse($refs.rewards)"
          >
            <ion-icon
              name="wallet-outline"
              class="text-xl mr-4 text-gray-700"
            />
            <span>Rewards</span>
          </a>
          <ul class="sub-nav collapse" ref="rewards">
            <li class="nav-item" v-if="canAccess('wallets')">
              <router-link
                :to="{ name: 'wallets' }"
                class="flex flex-row items-center text-xs opacity-75"
              >
                <span>Wallets</span>
              </router-link>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'share-and-earn' }"
            class="flex flex-row items-center text-xs opacity-75"
          >
            <ion-icon name="share-social-outline" class="text-xl mr-4 text-gray-700"></ion-icon>
            <span class="text-credpal-400">Refer & Earn</span>
          </router-link>
        </li>
      </ul>
    </template>

    <template>
      <div class="nav-title">Settings</div>
      <ul class="nav">
        <li class="nav-item" v-if="canAccess('settings-profile')">
          <router-link
            :to="{ name: 'settings-profile' }"
            class="flex flex-row items-center text-xs opacity-75"
          >
            <svg
              width="13"
              height="12"
              class="mr-4"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.5213 0.5C8.5235 0.5 10.1849 2.16532 10.1849 4.17224C10.1849 5.41056 9.54589 6.52077 8.6087 7.16128C10.1849 7.80178 11.4629 9.1255 11.9741 10.7908C12.0593 11.0897 11.9315 11.3886 11.6333 11.474C11.3351 11.5594 11.0369 11.4313 10.9517 11.1324C10.3553 9.1682 8.5235 7.84449 6.5213 7.84449C4.4765 7.84449 2.64471 9.1682 2.04831 11.1324C1.96311 11.4313 1.66491 11.5594 1.36671 11.474C1.06851 11.3886 0.940712 11.0897 1.02591 10.7908C1.53711 9.1255 2.81511 7.80178 4.3913 7.16128C3.45411 6.52077 2.81511 5.41056 2.81511 4.17224C2.81511 2.16532 4.4765 0.5 6.5213 0.5ZM6.5213 1.61021C5.0729 1.61021 3.92271 2.76313 3.92271 4.17224C3.92271 5.62406 5.0729 6.77697 6.5213 6.77697C7.9271 6.77697 9.07729 5.62406 9.07729 4.17224C9.07729 2.76313 7.9271 1.61021 6.5213 1.61021Z"
                fill="#252A32"
                stroke="#252A32"
                stroke-width="0.1"
              />
            </svg>

            <span class="text-credpal-400">My Account</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'settings-password' }"
            class="flex flex-row items-center text-xs opacity-75"
          >
            <ion-icon
              name="lock-closed-outline"
              class="text-12 text-credpal-400 mr-4"
            />
            <span class="text-credpal-400">Change Password</span>
          </router-link>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
export default {
  methods: {
    collapse(element) {
      window.collapse(element, "toggle");
    },
  },
  computed: {
    staffAccountType() {
      return this.$store.state.session.staff_account_type;
    },
    transaction() {
      return require("@/assets/icons/sm/transaction.svg");
    },
    loan() {
      return require("@/assets/icons/sm/loan.svg");
    },
    dashboard() {
      return require("@/assets/icons/sm/dashboard.svg");
    },
    history() {
      return require("@/assets/icons/sm/history.svg");
    },
  },
};
</script>