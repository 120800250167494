<template>
  <div class="bg-black bg-opacity-50 fixed grid h-full inset-0 place-items-center w-full z-100">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Overlay',
};
</script>
