import axios from 'axios';

export default {
  namespaced: true,
  state: {
    /*
    ------------------------------------------------------------------
    Closed Modals
    ------------------------------------------------------------------
    */
    modals: [],
  },
  mutations: {
    closeModal(state, { modal }) {
      state.modals.push(modal);
    },
    clearAll(state) {
      state.modals = [];
    },
  },
  actions: {
    clearAll({ commit }) {
      commit('clearAll');
    },
    closeModal({ commit, rootState }, { modal }) {
      commit('closeModal', { modal });

      const app = this._vm;

      axios({
        url: `${app.$baseurl}/modals/close/${modal}`,
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${rootState.session.token}`
        }
      });
    },
  }
}