import Vue from 'vue';
const AsyncFunction = Object.getPrototypeOf(async function() {}).constructor;

Vue.mixin({
  data() {
    return {
      apiRequests: {
        admin: {
          assignments: {
            async addMembers(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/admin/personal/assignments/admins/addMembers`,
                headers: this.headers,
                ...config
              });
            },
            async removeMembers(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/admin/personal/assignments/admins/removeMembers`,
                headers: this.headers,
                ...config
              });
            },
            async admins(category, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admin/personal/assignments/admins/${category}`,
                headers: this.headers,
                ...config
              });
            },
            async assign(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/admin/personal/assignments/assign`,
                headers: this.headers,
                ...config
              });
            },
            async unassign(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/admin/personal/assignments/unassign`,
                headers: this.headers,
                ...config
              });
            },
            async contact(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/admin/personal/assignments/mark-as-contacted`,
                headers: this.headers,
                ...config
              });
            }
          },
          admins: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admins`,
                headers: this.headers,
                ...config
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/admins`,
                headers: this.headers,
                ...config
              });
            },
            async update(adminId, config = {}) {
              return await this.$put({
                url: `${this.$baseurl}/admins/${adminId}`,
                headers: this.headers,
                ...config
              });
            },
            async delete(adminId, config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/admins/delete/${adminId}`,
                headers: this.headers,
                ...config
              });
            }
          },
          cards: {
            corporate: {
              async all(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/corporate/cards`,
                  headers: this.headers,
                  ...config
                });
              },
              async repayments(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/corporate/cards/repayments`,
                  headers: this.headers,
                  ...config
                });
              }
            },
            personal: {
              async all(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/personal/cards`,
                  headers: this.headers,
                  ...config
                });
              },
              async requests(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/personal/cards/all`,
                  headers: this.headers,
                  ...config
                });
              },
              async approve(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/personal/card/approve`,
                  headers: this.headers,
                  ...config
                });
              },
              async decline(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/personal/card/decline`,
                  headers: this.headers,
                  ...config
                });
              },
              async repayments(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/personal/cards/repayments`,
                  headers: this.headers,
                  ...config
                });
              },
              statements: {
                async getStatementBreakDown(userId, config = {}) {
                  return await this.$get({
                    url: `${this.$baseurl}/admin/statements/${userId}/breakdown`,
                    headers: this.headers,
                    ...config
                  });
                }
              },
              async chargeUser(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/charge`,
                  headers: this.headers,
                  ...config
                });
              },
              async markCardAsPaid(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/repayments/initiate/mark`,
                  headers: this.headers,
                  ...config
                });
              },
              async markCardAsUnPaid(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/repayments/terminate/mark`,
                  headers: this.headers,
                  ...config
                });
              },
              async settleRepayment(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/repayments/finalize/mark`,
                  headers: this.headers,
                  ...config
                });
              },
              async unSettleRepayment(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/repayments/unmark`,
                  headers: this.headers,
                  ...config
                });
              }
            },
            transactions: {
              async sendToTangarine(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/cards/tangerine/disburse`,
                  headers: this.headers,
                  ...config
                });
              },
              async payTangerine(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/cards/tangerine/pay-many`,
                  headers: this.headers,
                  ...config
                });
              },
              async payTangerineBatch(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/cards/tangerine/pay-batch`,
                  headers: this.headers,
                  ...config
                });
              }
            }
          },
          companies: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies`,
                headers: this.headers,
                ...config
              });
            },
            async requests(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/requests`,
                headers: this.headers,
                ...config
              });
            },
            async statistics(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admin/corporate/companies/statistics`,
                headers: this.headers,
                ...config
              });
            },
            creditApplications: {
              async all(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/companies/card/requests/all`,
                  headers: this.headers,
                  ...config
                });
              },
              async approve(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/companies/wallet/set-wallet-limit`,
                  headers: this.headers,
                  ...config
                });
              }
            },
            loans: {
              async all(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/corporate/loans`,
                  headers: this.headers,
                  ...config
                });
              },
              async approved(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/corporate/loans/approved`,
                  headers: this.headers,
                  ...config
                });
              },
              async disbursed(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/corporate/loans/disbursed`,
                  headers: this.headers,
                  ...config
                });
              }
            }
          },
          configurations: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/configurations`,
                headers: this.headers,
                ...config
              });
            }
          },
          users: {
            appealRequests: {
              approve: async (userId, config = {}) => {
                console.log(userId);
                await this.$post({
                  url: `${this.$baseurl}/admin/personal/users/appeal-requests/approve/${userId}`,
                  headers: this.headers,
                  ...config
                });
              },
              decline: async (userId, config = {}) => {
                await this.$post({
                  url: `${this.$baseurl}/admin/personal/users/appeal-requests/decline/${userId}`,
                  headers: this.headers,
                  ...config
                });
              }
            },
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admin/personal/users`,
                headers: this.headers,
                ...config
              });
            },
            async show(userId, queryParams = {}, config = {}) {
              const query = Object.keys(queryParams)
                .map(key => `${key}=${queryParams[key]}`)
                .join('&');
              return await this.$get({
                url: `${this.$baseurl}/admin/personal/users/${userId}?${query}`,
                headers: this.headers,
                ...config
              });
            },
            async search(params = {}, config = {}) {
              const query = Object.keys(params)
                .map(key => `${key}=${params[key]}`)
                .join('&');

              return await this.$get({
                url: `${this.$baseurl}/admin/personal/users/search?${query}`,
                headers: this.headers,
                ...config
              });
            },
            async status(status, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admin/personal/users/status/${status}`,
                headers: this.headers,
                ...config
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/admin/personal/users`,
                headers: this.headers,
                ...config
              });
            },
            async approve(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/staff/profile/activate`,
                headers: this.headers,
                ...config
              });
            },
            async increaseLimit(userId, config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/admin/personal/users/${userId}/increase-limit`,
                headers: this.headers,
                ...config
              });
            },
            async markIncreaseLimitAsReviewed(userId, config = {}) {
              return await this.$put({
                url: `${this.$baseurl}/admin/personal/users/${userId}/increase-limit/review`,
                headers: this.headers,
                ...config
              });
            },
            async verify(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/staff/profile/verify`,
                headers: this.headers,
                ...config
              });
            },
            async delete(userId, config = {}) {
              return await this.$_delete({
                url: `${this.$baseurl}/admin/personal/users/${userId}`,
                headers: this.headers,
                ...config
              });
            },
            async deactivate(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/staff/profile/deactivate`,
                headers: this.headers,
                ...config
              });
            },
            async decline(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/staff/profile/decline`,
                headers: this.headers,
                ...config
              });
            },
            async uncertain(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/staff/profile/uncertain`,
                headers: this.headers,
                ...config
              });
            },
            async activateDebitCard(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/staff/profile/activate-debit-card`,
                headers: this.headers,
                ...config
              });
            },
            async updateStatus(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/admin/personal/users/status`,
                headers: this.headers,
                ...config
              });
            },
            async signupStatistics(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admin/personal/users/leads/statistics`,
                headers: this.headers,
                ...config
              });
            },
            async underwritingStatistics(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admin/personal/users/underwriting/statistics`,
                headers: this.headers,
                ...config
              });
            },
            async updateProfile(userId, config = {}) {
              return await this.$put({
                url: `${this.$baseurl}/admin/personal/users/${userId}/profile`,
                headers: this.headers,
                ...config
              });
            },
            assigned: {
              async pending(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/personal/users/assigned-to-me?status=pending`,
                  headers: this.headers,
                  ...config
                });
              },
              async contacted(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/personal/users/assigned?status=contacted`,
                  headers: this.headers,
                  ...config
                });
              },
              async stats(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/personal/users/assigned/stats`,
                  headers: this.headers,
                  ...config
                });
              }
            },
            bankAccount: {
              async refute(userId, config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/users/${userId}/bank-account/refute`,
                  headers: this.headers,
                  ...config
                });
              },
              async verify(userId, config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/users/${userId}/bank-account/verify`,
                  headers: this.headers,
                  ...config
                });
              }
            },
            comments: {
              async all(userId, config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/personal/users/${userId}/comments`,
                  headers: this.headers,
                  ...config
                });
              }
            },
            creditApplications: {
              async all(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/personal/credit-applications`,
                  headers: this.headers,
                  ...config
                });
              },
              async approve(applicationId, config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/credit-applications/approve/${applicationId}`,
                  headers: this.headers,
                  ...config
                });
              },
              async decline(applicationId, config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/credit-applications/decline/${applicationId}`,
                  headers: this.headers,
                  ...config
                });
              }
            },
            documents: {
              async bankStatement(userId, config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/users/${userId}/documents/bank-statement`,
                  headers: this.headers,
                  ...config
                });
              },
              async blankBankStatement(userId, config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/personal/users/bank-statement/${userId}`,
                  headers: this.headers,
                  ...config
                });
              },
              async govtId(userId, config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/users/${userId}/documents/govt-id`,
                  headers: this.headers,
                  ...config
                });
              },
              async workId(userId, config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/users/${userId}/documents/work-id`,
                  headers: this.headers,
                  ...config
                });
              },
              async creditReport(userId, config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/users/${userId}/documents/credit-report`,
                  headers: this.headers,
                  ...config
                });
              },
              async custom(userId, config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/users/${userId}/documents/custom`,
                  headers: this.headers,
                  ...config
                });
              },
              async request(userId, config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/users/${userId}/documents/request`,
                  headers: this.headers,
                  ...config
                });
              },
              async invalidate(userId, documentId, config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/users/${userId}/documents/${documentId}/invalidate`,
                  headers: this.headers,
                  ...config
                });
              },
              async documentEvent(event, config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/personal/users/documents-event/${event}`,
                  headers: this.headers,
                  ...config
                });
              },
              async confirmMbs(userId, config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/users/${userId}/documents/confirm-mbs`,
                  headers: this.headers,
                  ...config
                });
              }
            },
            loanbot: {
              async getStatementAnalysis(userId, config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/personal/loanbot/statement-analysis/${userId}`,
                  headers: this.headers,
                  ...config
                });
              },
              async downloadStatement(userId, config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/personal/loanbot/statement-pdf/${userId}`,
                  headers: this.headers,
                  ...config
                });
              },
              async getCreditSummary(userId, config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/personal/loanbot/credit-summary/${userId}`,
                  headers: this.headers,
                  ...config
                });
              },
              async instantApproval(userId, config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/loanbot/verifications/${userId}`,
                  headers: this.headers,
                  ...config
                });
              },
              async updateAnalyses(userId, config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/loanbot/update-analyses/${userId}`,
                  headers: this.headers,
                  ...config
                });
              }
            },
            profile: {
              async updateAdditionalPhone(userId, config = {}) {
                return await this.$put({
                  url: `${this.$baseurl}/admin/personal/users/${userId}/profile/update`,
                  headers: this.headers,
                  ...config
                });
              },
              employment: {
                async employed(userId, config = {}) {
                  return await this.$put({
                    url: `${this.$baseurl}/admin/personal/users/${userId}/profile/employment/employed`,
                    headers: this.headers,
                    ...config
                  });
                },
                async selfEmployed(userId, config = {}) {
                  return await this.$put({
                    url: `${this.$baseurl}/admin/personal/users/${userId}/profile/employment/self-employed`,
                    headers: this.headers,
                    ...config
                  });
                }
              },
              async orderHistory(userId, config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/personal/users/${userId}/order-transactions`,
                  headers: this.headers,
                  ...config
                });
              }
            }
          },
          loans: {
            personal: {
              async all(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/personal/loans`,
                  headers: this.headers,
                  ...config
                });
              },
              async statistics(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/personal/loans/statistics`,
                  headers: this.headers,
                  ...config
                });
              },
              async view(loanId, config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/personal/loans/${loanId}`,
                  headers: this.headers,
                  ...config
                });
              },
              async approve(loanId, config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/loans/${loanId}/approve`,
                  headers: this.headers,
                  ...config
                });
              },
              async decline(loanId, config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/loans/${loanId}/decline`,
                  headers: this.headers,
                  ...config
                });
              },
              async modify(loanId, config = {}) {
                return await this.$put({
                  url: `${this.$baseurl}/admin/personal/loans/${loanId}/modify`,
                  headers: this.headers,
                  ...config
                });
              },
              async total(status, config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/personal/loans/total?status=${status}`,
                  headers: this.headers,
                  ...config
                });
              },
              async disburse_many(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/loans/disburse-many`,
                  headers: this.headers,
                  ...config
                });
              },
              async disburse_credpal_pay(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/loans/disburse-credpalpay-many`,
                  headers: this.headers,
                  ...config
                });
              },
              async undisburse_many(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/loans/undisburse-many`,
                  headers: this.headers,
                  ...config
                });
              },
              async send_to_tangarine(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/loans/tangerine/disburse`,
                  headers: this.headers,
                  ...config
                });
              },
              async pay_tangerine(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/loans/tangerine/pay-many`,
                  headers: this.headers,
                  ...config
                });
              },
              async payTangerineBatch(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/loans/tangerine/pay-batch`,
                  headers: this.headers,
                  ...config
                });
              },
              async officersVerify(loanId, config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/loans/${loanId}/officers-verify`,
                  headers: this.headers,
                  ...config
                });
              },
              async operationsVerify(loanId, config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/loans/${loanId}/operations-verify`,
                  headers: this.headers,
                  ...config
                });
              },
              async settle_many_repayments(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/repayments/settle-many`,
                  headers: this.headers,
                  ...config
                });
              },
              async loanRequestStop(loanId, config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/personal/loans/${loanId}/request-stop`,
                  headers: this.headers,
                  ...config
                });
              }
            },
            async letterOfIndebtedness(loanId, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/loans/letter-of-indebtedness/${loanId}`,
                headers: this.headers,
                ...config
              });
            }
          },
          roles: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admin/roles`,
                headers: this.headers,
                ...config
              });
            },
            async update(config = {}) {
              return await this.$put({
                url: `${this.$baseurl}/admin/roles/users/update`,
                headers: this.headers,
                ...config
              });
            }
          },
          permissions: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admin/permissions`,
                headers: this.headers,
                ...config
              });
            }
          },
          affiliate: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admin/affiliates`,
                headers: this.headers,
                ...config
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/admin/affiliates`,
                headers: this.headers,
                ...config
              });
            },
            async conversions(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admin/affiliates/conversions`,
                headers: this.headers,
                ...config
              });
            },
            rewards: {
              async all(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/affiliates/rewards`,
                  headers: this.headers,
                  ...config
                });
              },
              async disbursed(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/affiliates/rewards/all-batch-request-to-withdraw?status=disbursed`,
                  headers: this.headers,
                  ...config
                });
              },
              async pending(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/affiliates/rewards/all-batch-request-to-withdraw?status=processing`,
                  headers: this.headers,
                  ...config
                });
              },
              async total(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/affiliate/rewards/total`,
                  headers: this.headers,
                  ...config
                });
              }
            },
            statistics: {
              async affiliates(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/affiliates/statistics`,
                  headers: this.headers,
                  ...config
                });
              }
            }
          },
          merchants: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admin/merchants`,
                headers: this.headers,
                ...config
              });
            },
            async pending(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admin/merchants/pending`,
                headers: this.headers,
                ...config
              });
            },
            async updateProfile(merchantId, config = {}) {
              return await this.$put({
                url: `${this.$baseurl}/admin/merchants/update-profile/${merchantId}`,
                headers: this.headers,
                ...config
              });
            },
            async category(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admin/merchants/industries`,
                headers: this.headers,
                ...config
              });
            },
            async updateCategory(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/admin/merchants/industry/update`,
                headers: this.headers,
                ...config
              });
            },
            async settings(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/admin/merchants/settings`,
                headers: this.headers,
                ...config
              });
            },
            async deleteSetting(id, config = {}) {
              return await this.$_delete({
                url: `${this.$baseurl}/admin/merchants/settings/${id}`,
                headers: this.headers,
                ...config
              });
            },
            async changeFeaturedStatus(merchantId, status, config = {}) {
              return await this.$put({
                url: `${this.$baseurl}/admin/merchants/featured/${merchantId}/${status}`,
                headers: this.headers,
                ...config
              });
            },
            async changeDealStatus(dealId, status, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admin/merchants/settings/${status}/${dealId}`,
                headers: this.headers,
                ...config
              });
            },
            async deactivatedDeals(merchantId, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admin/merchants/settings/deactivated/${merchantId}`,
                headers: this.headers,
                ...config
              });
            },
            async vendorAccess(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admin/merchants/vendor-access`,
                headers: this.headers,
                ...config
              });
            },
            async storeVendorAccess(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/admin/merchants/vendor-access`,
                headers: this.headers,
                ...config
              });
            },
            branch: {
              async add(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/merchants/branch`,
                  headers: this.headers,
                  ...config
                });
              },
              async destroy(branchId, config = {}) {
                return await this.$_delete({
                  url: `${this.$baseurl}/admin/merchants/branch/${branchId}`,
                  headers: this.headers,
                  ...config
                });
              },
              async update(branchId, config = {}) {
                return await this.$put({
                  url: `${this.$baseurl}/admin/merchants/branch/${branchId}`,
                  headers: this.headers,
                  ...config
                });
              }
            },
            region: {
              async add(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/admin/merchants/region`,
                  headers: this.headers,
                  ...config
                });
              },
              async destroy(id, config = {}) {
                return await this.$_delete({
                  url: `${this.$baseurl}/admin/merchants/region/${id}`,
                  headers: this.headers,
                  ...config
                });
              }
            },
            settlements: {
              async pending(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/merchants/transactions/settlements/pending`,
                  headers: this.headers,
                  ...config
                });
              },
              async paid(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/merchants/transactions/settlements/paid`,
                  headers: this.headers,
                  ...config
                });
              }
            },
            transactions: {
              async all(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/merchants/all/orders`,
                  headers: this.headers,
                  ...config
                });
              },
              async merchantOrders(merchantId, config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/admin/merchants/${merchantId}/orders`,
                  headers: this.headers,
                  ...config
                });
              }
            },
            async saveConfigurationChanges(merchantId, config = {}) {
              return await this.$put({
                url: `${this.$baseurl}/admin/merchants/${merchantId}/configurations`,
                headers: this.headers,
                ...config
              });
            }
          },
          wallets: {
            async cashbacks(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admin/transactions/cashback`,
                headers: this.headers,
                ...config
              });
            },
            async rewards(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admin/personal/users/wallet/reward`,
                headers: this.headers,
                ...config
              });
            },
            async users(userId, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admin/personal/users/wallet/${userId}/reward`,
                headers: this.headers,
                ...config
              });
            },
            async userCashback(userId, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admin/personal/users/${userId}/cashback`,
                headers: this.headers,
                ...config
              });
            },
            async transactions(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admin/personal/users/wallet/transactions`,
                headers: this.headers,
                ...config
              });
            }
          },
          lenders: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admin/lenders`,
                headers: this.headers,
                ...config
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/admin/lenders`,
                headers: this.headers,
                ...config
              });
            },
            async delete(lenderId, config = {}) {
              return await this.$_delete({
                url: `${this.$baseurl}/admin/lenders/${lenderId}`,
                headers: this.headers,
                ...config
              });
            }
          },
          repayments: {
            async all(config = {}) {
              return this.$get({
                url: `${this.$baseurl}/repayments`,
                headers: this.headers,
                ...config
              });
            },
            async defaults(config = {}) {
              return this.$get({
                url: `${this.$baseurl}/admin/personal/repayments/defaults`,
                headers: this.headers,
                ...config
              });
            },
            async statistics(query, config = {}) {
              return this.$get({
                url: `${this.$baseurl}/admin/personal/repayments/statistics?${query}`,
                headers: this.headers,
                ...config
              });
            },
            async settle(repaymentId, config = {}) {
              return this.$post({
                url: `${this.$baseurl}/admin/personal/repayments/${repaymentId}/settle`,
                headers: this.headers,
                ...config
              });
            },
            async fractionalPayment(repaymentId, config = {}) {
              return this.$post({
                url: `${this.$baseurl}/admin/personal/repayments/${repaymentId}/pay-fraction`,
                headers: this.headers,
                ...config
              });
            },
            async getRepaymentWallet(userId, config = {}) {
              return this.$get({
                url: `${this.$baseurl}/admin/personal/repayments/wallet/${userId}`,
                headers: this.headers,
                ...config
              });
            },
            async getRepaymentWalletHistory(walletId, config = {}) {
              return this.$get({
                url: `${this.$baseurl}/admin/personal/repayments/wallet/${walletId}/history`,
                headers: this.headers,
                ...config
              });
            }
          },
          statistics: {
            async daily(params, config = {}) {
              const query = Object.keys(params)
                .map(key => `${key}=${params[key]}`)
                .join('&');
              return await this.$get({
                url: `${this.$baseurl}/admin/statistics/daily?${query}`,
                headers: this.headers,
                ...config
              });
            }
          }
        },
        corporate: {
          creditApplication: {
            async backendCheck(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/creditLimit/request`,
                headers: this.headers,
                ...config
              });
            },
            async submitApplication(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/creditLimit/request/final`,
                headers: this.headers,
                ...config
              });
            },
            async documents(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/documents/upload`,
                headers: this.headers,
                ...config
              });
            }
          },
          subscription: {
            async getActivePlan(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/plan/unique-plan/${this.user.company_id}`,
                headers: this.headers,
                ...config
              });
            },
            async getPlans(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/wallet/fund-debit-wallet`,
                headers: this.headers,
                ...config
              });
            },
            async subscribe(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/wallet/fund-debit-wallet`,
                headers: this.headers,
                ...config
              });
            }
          },
          wallet: {
            async fundCreditWallet(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/wallet/fund-credit-wallet`,
                headers: this.headers,
                ...config
              });
            },
            async fundDebitWallet(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/wallet/fund-debit-wallet`,
                headers: this.headers,
                ...config
              });
            },
            async get(companyId, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/wallet/${companyId}`,
                headers: this.headers,
                ...config
              });
            }
          },
          cards: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/cards/all/${this.user.company_id}`,
                headers: this.headers,
                ...config
              });
            },
            async transactions(accountId, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/account/transactions/${accountId}`,
                headers: this.headers,
                ...config
              });
            },
            credit: {
              async all(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/companies/cards`,
                  headers: this.headers,
                  ...config
                });
              },
              async new(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/companies/creditCard/create`,
                  headers: this.headers,
                  ...config
                });
              },
              async fund(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/companies/wallet/assign-credit`,
                  headers: this.headers,
                  ...config
                });
              },
              async increase_limit(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/companies/account/set-account-limit`,
                  headers: this.headers,
                  ...config
                });
              }
            },
            debit: {
              async all(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/companies/cards`,
                  headers: this.headers,
                  ...config
                });
              },
              async new(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/companies/debitCard/create`,
                  headers: this.headers,
                  ...config
                });
              },
              async fund(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/companies/wallet/assign-debit`,
                  headers: this.headers,
                  ...config
                });
              }
            }
          },
          groups: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/groups`,
                headers: this.headers,
                ...config
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/creditCard/create`,
                headers: this.headers,
                ...config
              });
            }
          },
          staff: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/staff/${this.user.company_id}`,
                headers: this.headers,
                ...config
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/creditCard/create`,
                headers: this.headers,
                ...config
              });
            }
          },
          admins: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/staff/${this.user.company_id}`,
                headers: this.headers,
                ...config
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/creditCard/create`,
                headers: this.headers,
                ...config
              });
            }
          },
          expenseGroups: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/admins`,
                headers: this.headers,
                ...config
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/creditCard/create`,
                headers: this.headers,
                ...config
              });
            }
          },
          repaymentCards: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/card/repayment/${this.user.company_id}`,
                headers: this.headers,
                ...config
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/card/repayment/setup`,
                headers: this.headers,
                ...config
              });
            }
          },
          activities: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/activities/${this.user.company_id}`,
                headers: this.headers,
                ...config
              });
            },
            async recent(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/activities/recent/${this.user.company_id}`,
                headers: this.headers,
                ...config
              });
            }
          },
          permissions: {
            async roles(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/permissions/roles`,
                headers: this.headers,
                ...config
              });
            },
            async users(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/permissions/users/${this.user.company_id}`,
                headers: this.headers,
                ...config
              });
            },
            async update(config = {}) {
              return await this.$put({
                url: `${this.$baseurl}/companies/permissions/users/update`,
                headers: this.headers,
                ...config
              });
            }
          },
          expenseCategories: {
            async all(companyId, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/expense/category/${companyId}`,
                headers: this.headers,
                ...config
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/expense/category`,
                headers: this.headers,
                ...config
              });
            }
          },
          expenseSubCategories: {
            async all(companyId, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/expense/category/sub-category/${companyId}`,
                headers: this.headers,
                ...config
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/expense/category/sub-category`,
                headers: this.headers,
                ...config
              });
            }
          },
          expensePolicies: {
            async all(companyId, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/expense/policy/company/${companyId}`,
                headers: this.headers,
                ...config
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/expense/policy`,
                headers: this.headers,
                ...config
              });
            },
            async update(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/expense/policy/update`,
                headers: this.headers,
                ...config
              });
            },
            async attach(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/expense/policy/attach-to-role`,
                headers: this.headers,
                ...config
              });
            },
            async delete(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/expense/policy/delete`,
                headers: this.headers,
                ...config
              });
            }
          },
          beneficiaries: {
            async all(companyId, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/beneficiaries/all/${companyId}`,
                headers: this.headers,
                ...config
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/beneficiaries/create`,
                headers: this.headers,
                ...config
              });
            }
          },
          transfers: {
            async all(companyId, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/transfers/${companyId}`,
                headers: this.headers,
                ...config
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/transfer/request`,
                headers: this.headers,
                ...config
              });
            },
            async approve(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/transfer/approve`,
                headers: this.headers,
                ...config
              });
            }
          },
          statements: {
            async all(companyId, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/statement/company/${companyId}`,
                headers: this.headers,
                ...config
              });
            },
            async download(statementId, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/statement/download/${statementId}`,
                headers: this.headers,
                ...config
              });
            }
          }
        },
        personal: {
          cards: {
            personal: {
              async activate(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/personal/card/activate`,
                  headers: this.headers,
                  ...config
                });
              },
              async all(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/staff/cards/${this.user.id}`,
                  headers: this.headers,
                  ...config
                });
              },
              async requestPin(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/personal/card/pin/request`,
                  headers: this.headers,
                  ...config
                });
              }
            },
            corporate: {
              async all(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/companies/cards/user/${this.user.id}`,
                  headers: this.headers,
                  ...config
                });
              },
              async requestPin(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/companies/card/pin/request`,
                  headers: this.headers,
                  ...config
                });
              },
              async activate(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/companies/creditCard/activate`,
                  headers: this.headers,
                  ...config
                });
              }
            },
            async transfer(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/transfer/initiate`,
                headers: this.headers,
                ...config
              });
            },
            async transactions(accountId, month = 0, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/personal/card/transactions/${accountId}?month=${month}`,
                headers: this.headers,
                ...config
              });
            }
          },
          bills: {
            flutterwave: {
              async categories(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/flutterwave/bill-categories`,
                  headers: this.headers,
                  ...config
                });
              },
              async getService(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/flutterwave/bill-payment`,
                  headers: this.headers,
                  ...config
                });
              }
            },
            capricorn: {
              async transactions(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/baxi/transaction/${this.user.id}`,
                  headers: this.headers,
                  ...config
                });
              },
              async categories(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/baxi/biller-services`,
                  headers: this.headers,
                  ...config
                });
              },
              async dataPlans(network, config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/baxi/provider-bundles/${network}`,
                  headers: this.headers,
                  ...config
                });
              },
              async buyAirtime(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/baxi/airtime-request`,
                  headers: this.headers,
                  ...config
                });
              },
              async buyData(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/baxi/databundle-request`,
                  headers: this.headers,
                  ...config
                });
              },
              async verifyMeterNumber(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/baxi/verify-electric-user`,
                  headers: this.headers,
                  ...config
                });
              },
              async buyElectricity(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/baxi/electricity-request`,
                  headers: this.headers,
                  ...config
                });
              },
              async cableTvPlans(provider, config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/baxi/multichoice-list/${provider}`,
                  headers: this.headers,
                  ...config
                });
              },
              async verifyCableTvAccount(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/baxi/verify-account-details`,
                  headers: this.headers,
                  ...config
                });
              },
              async buyCableTvBundle(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/baxi/multichoice-request`,
                  headers: this.headers,
                  ...config
                });
              }
            }
          },
          documents: {
            async govtId(userId, config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/staff/documents/govt_id/${userId}`,
                headers: {
                  ...this.headers,
                  'Content-Type': 'application/json'
                },
                ...config
              });
            },
            async workId(userId, config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/staff/documents/work_id/${userId}`,
                headers: {
                  ...this.headers,
                  'Content-Type': 'application/json'
                },
                ...config
              });
            },
            async bankStatement(userId, config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/staff/documents/bank_statement/${userId}`,
                headers: {
                  ...this.headers,
                  'Content-Type': 'application/json'
                },
                ...config
              });
            },
            async custom(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/personal/documents/custom`,
                headers: {
                  ...this.headers,
                  'Content-Type': 'application/json'
                },
                ...config
              });
            },
            async getUrl(documentId, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/staff/documents/${documentId}`,
                headers: this.headers,
                ...config
              });
            }
          },
          expenseCategories: {
            async all(companyId, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/expense/category/${companyId}`,
                headers: this.headers,
                ...config
              });
            }
          },
          expenses: {
            async request(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/expense/request`,
                headers: this.headers,
                ...config
              });
            },
            async report(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/account/expense/update`,
                headers: this.headers,
                ...config
              });
            }
          },
          expenseRequests: {
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/companies/expense/request/pending`,
                headers: this.headers,
                ...config
              });
            },
            async approve(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/expense/request/approve`,
                headers: this.headers,
                ...config
              });
            },
            async decline(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/companies/expense/request/approve`,
                headers: this.headers,
                ...config
              });
            }
          },
          loans: {
            offerLetter: {
              async loanOfferLetter(loanId, config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/personal/loans/${loanId}/offer-letter`,
                  headers: this.headers,
                  ...config
                });
              },
              async accept(offerLetterId, config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/personal/loans/offer-letter/${offerLetterId}/accept`,
                  headers: this.headers,
                  ...config
                });
              },
              async reject(offerLetterId, config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/personal/loans/offer-letter/${offerLetterId}/reject`,
                  headers: this.headers,
                  ...config
                });
              }
            },
            async all(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/personal/loans`,
                headers: this.headers,
                ...config
              });
            },
            async request(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/staff/loans/request`,
                headers: this.headers,
                ...config
              });
            },
            async view(loanId, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/loans/${loanId}`,
                headers: this.headers,
                ...config
              });
            }
          },
          marketplace: {
            async merchants(limit, search, filters = [], config = {}) {
              return await this.$get({
                url: `${
                  this.$baseurl
                }/merchant?limit=${limit}&search=${search ??
                  ''}&filter=${filters ?? ''}`,
                headers: this.headers,
                ...config
              });
            },
            async branch(vendorId, search, config = {}) {
              // const url =
              //   typeof search === undefined || search === null
              //     ? `${this.$baseurl}/merchant/${vendorId}/branch`
              //     : `${this.$baseurl}/merchant/${vendorId}/branch?search=${search}`;
              return await this.$get({
                url: `${
                  this.$baseurl
                }/merchant/${vendorId}/branch?search=${search ?? ''}`,
                headers: this.headers,
                ...config
              });
            },
            async categories(limit, search, filters = [], config = {}) {
              return await this.$get({
                url: `${
                  this.$baseurl
                }/merchant/industry?limit=${limit}&search=${search ??
                  ''}&filter=${filters ?? ''}`,
                headers: this.headers,
                ...config
              });
            },
            async categoryVendors(categoryId, search, config = {}) {
              const url =
                typeof search === undefined || search === ''
                  ? `${this.$baseurl}/merchant/${categoryId}/merchants`
                  : `${this.$baseurl}/merchant/${categoryId}/merchants?search=${search}`;

              return await this.$get({
                url: url,
                headers: this.headers,
                ...config
              });
            },
            async deals(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/merchant/deal`,
                headers: this.headers,
                ...config
              });
            },
            async placeOrder(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/merchant/create-order`,
                headers: this.headers,
                ...config
              });
            },
            async confirmOrder(orderId, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/merchant/${orderId}/confirm-order`,
                headers: this.headers,
                ...config
              });
            },
            async cancelOrder(orderId, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/merchant/${orderId}/cancel-order`,
                headers: this.headers,
                ...config
              });
            },
            async spreadPayment(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/merchant/spread-payment`,
                headers: this.headers,
                ...config
              });
            },
            async orderHistory(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/personal/users/transaction/transfer-purchases`,
                headers: this.headers,
                ...config
              });
            },
            paymentSummary: {
              async paymentMethod(orderAmount, merchantId, config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/merchant/payment-summary/v2/${merchantId}/${orderAmount}`,
                  headers: this.headers,
                  ...config
                });
              },
              async oneTimeSummary(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/merchant/payment-summary/onetime`,
                  headers: this.headers,
                  ...config
                });
              },
              async spreadPaymentSummary(config = {}) {
                return await this.$post({
                  url: `${this.$baseurl}/merchant/payment-summary/spread-payment`,
                  headers: this.headers,
                  ...config
                });
              }
            }
          },
          onboarding: {
            documents: {
              async bankStatement(config = {}) {
                await this.$post({
                  url: `${this.$baseurl}/personal/onboarding/v2/documents/bank-statement`,
                  headers: this.headers,
                  ...config
                });
              },
              async confirmMbs(config = {}) {
                await this.$post({
                  url: `${this.$baseurl}/personal/onboarding/v2/documents/bank-statement/mbs/confirm`,
                  headers: this.headers,
                  ...config
                });
              },
              async govtId(config = {}) {
                await this.$post({
                  url: `${this.$baseurl}/personal/onboarding/v2/documents/govt-id`,
                  headers: this.headers,
                  ...config
                });
              },
              async mbs(config = {}) {
                await this.$post({
                  url: `${this.$baseurl}/personal/onboarding/v2/documents/bank-statement/mbs`,
                  headers: this.headers,
                  ...config
                });
              },
              async mobile(config = {}) {
                await this.$post({
                  url: `${this.$baseurl}/personal/onboarding/v2/documents/bank-statement/mobile`,
                  headers: this.headers,
                  ...config
                });
              },
              async mono(config = {}) {
                await this.$post({
                  url: `${this.$baseurl}/personal/onboarding/v2/documents/bank-statement/mono`,
                  headers: this.headers,
                  ...config
                });
              },
              async okra(config = {}) {
                await this.$post({
                  url: `${this.$baseurl}/personal/onboarding/v2/documents/bank-statement/okra`,
                  headers: this.headers,
                  ...config
                });
              },
              async ussd(config = {}) {
                await this.$post({
                  url: `${this.$baseurl}/personal/onboarding/v2/documents/bank-statement/ussd`,
                  headers: this.headers,
                  ...config
                });
              },
              async workId(config = {}) {
                await this.$post({
                  url: `${this.$baseurl}/personal/onboarding/v2/documents/work-id`,
                  headers: this.headers,
                  ...config
                });
              }
            },
            nin: {
              async requestOtp(config = {}) {
                await this.$post({
                  url: `${this.$baseurl}/personal/onboarding/v2/nin-verification/request-otp`,
                  headers: this.headers,
                  ...config
                });
              },
              async verifyOtp(config = {}) {
                await this.$post({
                  url: `${this.$baseurl}/personal/onboarding/v2/nin-verification/verify-otp`,
                  headers: this.headers,
                  ...config
                });
              }
            },
            workEmail: {
              async requestOtp(config = {}) {
                await this.$post({
                  url: `${this.$baseurl}/personal/onboarding/v2/work-email/request-otp`,
                  headers: this.headers,
                  ...config
                });
              },
              async verifyOtp(config = {}) {
                await this.$post({
                  url: `${this.$baseurl}/personal/onboarding/v2/work-email/verify-otp`,
                  headers: this.headers,
                  ...config
                });
              }
            },
            bvn: {
              async requestOtp(config = {}) {
                await this.$post({
                  url: `${this.$baseurl}/personal/onboarding/v2/identity-verification/request-otp`,
                  headers: this.headers,
                  ...config
                });
              },
              async verifyOtp(config = {}) {
                await this.$post({
                  url: `${this.$baseurl}/personal/onboarding/v2/identity-verification/verify-otp`,
                  headers: this.headers,
                  ...config
                });
              }
            },
            async identityVerification(config = {}) {
              await this.$post({
                url: `${this.$baseurl}/personal/onboarding/v2/identity-verification`,
                headers: this.headers,
                ...config
              });
            },
            async instantApproval(config = {}) {
              await this.$post({
                url: `${this.$baseurl}/personal/onboarding/v2/instant-approval`,
                headers: this.headers,
                ...config
              });
            },
            async profile(config = {}) {
              await this.$put({
                url: `${this.$baseurl}/personal/onboarding/v2/profile`,
                headers: this.headers,
                ...config
              });
            }
          },
          profile: {
            async update(config = {}) {
              return await this.$put({
                url: `${this.$baseurl}/personal/profile`,
                headers: {
                  ...this.headers,
                  'Content-Type': 'application/json'
                },
                ...config
              });
            }
          },
          repayments: {
            async upcoming(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/staff/repayments/upcoming/${this.user.id}`,
                headers: this.headers,
                ...config
              });
            }
          },
          repaymentCards: {
            async all(userId, config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/staff/card/repayment/${userId}`,
                headers: this.headers,
                ...config
              });
            },
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/staff/card/repayment/setup`,
                headers: this.headers,
                ...config
              });
            }
          },
          shareAndEarn: {
            async referralList(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/personal/users/referral`,
                headers: this.headers,
                ...config
              });
            },
            async summary(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/personal/users/referral/summary`,
                headers: this.headers,
                ...config
              });
            },
            async walletBalance(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/personal/users/referral/wallet`,
                headers: this.headers,
                ...config
              });
            },
            async redeem(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/personal/users/referral/wallet/redeem`,
                headers: this.headers,
                ...config
              });
            },
            async generateLink(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/personal/users/referral/generate-link`,
                headers: this.headers,
                ...config
              });
            }
          },
          subscription: {
            async getPlans(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/personal/accessible-plans`,
                headers: this.headers,
                ...config
              });
            },
            async subscribe(config = {}) {
              this.$refs.payLaterModal.close();
              return await this.$post({
                url: `${this.$baseurl}/personal/card/request/final`,
                headers: this.headers,
                ...config
              });
            },
            async verifyAccount(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/personal/card/request`,
                headers: this.headers,
                ...config
              });
            }
          },
          transfers: {
            async new(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/personal/debit-card/transfer`,
                headers: this.headers,
                ...config
              });
            },
            async clearBalanceTransfer(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/repayment/personal/instant-transfer`,
                headers: this.headers,
                ...config
              });
            },
            async loanRepaymentTransfer(config = {}) {
              return await this.$post({
                url: `${this.$baseurl}/staff/repayments/transfer`,
                headers: this.headers,
                ...config
              });
            }
          },
          wallet: {
            async redemption(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/personal/users/transaction/vault/statement-credit`,
                headers: this.headers,
                ...config
              });
            },
            async redeemWallet(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/personal/users/transaction/redeem-wallet`,
                headers: this.headers,
                ...config
              });
            },
            async snapshot(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/personal/users/wallet-snapshot`,
                headers: this.headers,
                ...config
              });
            },
            async balance(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/personal/users/vault-balances`,
                headers: this.headers,
                ...config
              });
            },
            async walletBalance(config = {}) {
              return await this.$get({
                url: `${this.$baseurl}/personal/users/wallet`,
                headers: this.headers,
                ...config
              });
            },
            transaction: {
              async cashbacks(config = {}) {
                return await this.$get({
                  url: `${this.$baseurl}/personal/users/transaction/cashbacks`,
                  headers: this.headers,
                  ...config
                });
              }
            }
          },
          async configurations(config = {}) {
            return await this.$get({
              url: `${this.$baseurl}/configurations/staff/${this.user?.id}`,
              headers: this.headers,
              ...config
            });
          }
        },
        registration: {
          async createAccount(config = {}) {
            return this.$post({
              url: `${this.$baseurl}/register-process/v2/register`,
              headers: this.headers,
              ...config
            });
          },
          async business(config = {}) {
            return this.$post({
              url: `${this.$baseurl}/register-process/v1/business`,
              headers: this.headers,
              ...config
            });
          },
          async whitelistedCompanies(search = '', config = {}) {
            return this.$get({
              url: `${this.$baseurl}/register-process/v2/companies?search=${search}`,
              headers: this.headers,
              ...config
            });
          }
        },
        authentication: {
          async loginWithAuthToken(config = {}) {
            return await this.$post({
              url: `${this.$baseurl}/login/token`,
              headers: this.headers,
              ...config
            });
          },
          async updatePassword(config = {}) {
            return await this.$put({
              url: `${this.$baseurl}/auth/v2/update-password`,
              headers: this.headers,
              ...config
            });
          }
        },
        otp: {
          async request(config = {}) {
            return this.$post({
              url: `${this.$baseurl}/otp/request`,
              headers: this.headers,
              ...config
            });
          }
        }
      }
    };
  },
  methods: {
    async sendRequest(path = '', ...params) {
      const fullPath = path.split('.');
      let request;

      try {
        request = fullPath.reduce(
          (last, current) => last[current],
          this.apiRequests
        );
      } catch (e) {
        console.warn(e);
      }

      if (
        !request ||
        (request.constructor !== Function &&
          request.constructor !== AsyncFunction)
      ) {
        throw new Error(
          `Invaliid Request ${path}; Request Not Registered In /src/mixins/apis.js`
        );
      }

      return await request.apply(this, [...params]);
    }
  }
});
