import Vue from 'vue';
import Pusher from 'pusher-js';
import Raphael from 'raphael/raphael';
import App from './App.vue';
import router from './router';
import store from './store';
import config from '../config';
import moment from 'moment';
import NProgress from 'vue-nprogress';
import './components';
import './mixins';
import './assets/sass/app.scss';
import './javascript';
import './slider';

global.Raphael = Raphael;

Vue.use(NProgress);

Vue.config.ignoredElements = [/^ion-/];
Vue.config.productionTip = false;
Vue.config.devtools = process.env.VUE_APP_ENV !== 'production';

Pusher.logToConsole = false;
const { APP_KEY, APP_CLUSTER } = config;

const pusher = new Pusher(APP_KEY, {
  encrypted: true,
  cluster: APP_CLUSTER
});

//moment globally
Vue.prototype.$moment = moment;

// Vue.prototype.pusher = pusher;
Vue.prototype.$baseurl = process.env.VUE_APP_API_BASEURL;
Vue.prototype.$loanbotUrl = process.env.VUE_APP_LOANBOT_BASEURL;

const nprogress = new NProgress();

window.App = new Vue({
  nprogress,
  router,
  store,
  ...App,
  methods: {
    ...App.methods,
    async initializeCustomerChannel(userId) {
      var channel = pusher.subscribe(`customer-${userId}`);

      await this.pusherEvent(channel, 'account-approval');
      await this.pusherEvent(channel, 'clear-balance');
    }
  }
}).$mount('#app');
