import Vue from 'vue';

Vue.mixin({
  data() {
    return {
      assets: {
        icons: {
          sm: {
            alertCircle: require('@/assets/icons/sm/alert-circle.svg'),
            back: require('@/assets/icons/sm/back.svg'),
            bills: require('@/assets/icons/sm/bills.svg'),
            cable: require('@/assets/icons/sm/cable.svg'),
            checkmarkCircle: require('@/assets/icons/sm/checkmark-circle.svg'),
            chevronRight: require('@/assets/icons/sm/chevron-right.svg'),
            clockCircleYellow: require('@/assets/icons/sm/clock-circle-yellow.svg'),
            errorCircle: require('@/assets/icons/sm/error-circle.svg'),
            flight: require('@/assets/icons/sm/flight.svg'),
            insurance: require('@/assets/icons/sm/insurance.svg'),
            internet: require('@/assets/icons/sm/internet.svg'),
            lightbulb: require('@/assets/icons/sm/lightbulb.svg'),
            limit: require('@/assets/icons/sm/limit.svg'),
            mobile_top_up: require('@/assets/icons/sm/mobile-top-up.svg'),
            others: require('@/assets/icons/sm/others.svg'),
            shieldWhite: require('@/assets/icons/sm/shield-white.svg'),
          },
          lg: {
            amount: require('@/assets/amount.svg'),
            checkmark: require('@/assets/checkmark-base.svg'),
            exclamation: require('@/assets/exclamation.svg'),
          },
          xl: {
            static: require('@/assets/icons/xl/static.png'),
            staticBlack: require('@/assets/icons/xl/static-black.png'),
          }
        }
      }
    }
  },
  computed: {
    isBigScreen() {
      return window.innerWidth > 768
    },
    isSmallScreen() {
      return window.innerWidth <= 768
    },
  }
});