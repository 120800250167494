<template>
  <div class="app-layout">

    <sidebar />

    <div class="app-content">
      <navbar />
      
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>

    <user-onboarding
      v-if="isOnboardingNeeded"
      ref="userOnboarding"
    />
  </div>
</template>

<script>
export default {
  name: 'AppLayout',
  components: {
    navbar: require('@/views/app/components/Navbar.vue').default,
    sidebar: require('@/views/app/components/sidebar/Index.vue').default,
  },
  data() {
    return {
      isUserOnboardingComponentMounted: false,
    };
  },
  computed: {
    excludedRoutesForOnboarding() {
      return [
        'settings-password', 'settings-profile'
      ];
    },
    isOnboardingNeeded() {
      return this.accountType === this.ENV_VUE_APP_CUSTOMER_PATH
        && this.excludedRoutesForOnboarding.every(this.validateOnboardingRoute);
    },
  },
  methods: {
    continueOnboarding() {
      if (this.isUserOnboardingComponentMounted)
        return;

      this.isUserOnboardingComponentMounted = true;

      this.$refs.userOnboarding.continueOnboarding();
    },
    validateOnboardingRoute(routeName) {
      return this.$route.name !== routeName;
    },
  },
}
</script>
