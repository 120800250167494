import Vue from 'vue';
import Alert from '@/components/presentation/alerts/Alert.vue';

Vue.mixin({
  methods: {
    $alert(props) {
      console.log('Alert method called');
      const { title, body, subBody } = props;
      const AlertModal = Vue.extend(Alert);
      const instance = new AlertModal({
        propsData: {
          ...props,
          mountedManually: true
        }
      });
      instance.$slots.title = [title];
      instance.$slots.body = [body];
      instance.$slots.subBody = [subBody];
      instance.$mount();
      document.body.appendChild(instance.$el);
      return instance;
    },
    $error(props) {
      return this.$alert({ ...props, status: 'error' });
    },
    $success(props) {
      return this.$alert({ ...props, status: 'success' });
    },
    $warning(props) {
      return this.$alert({ ...props, status: 'warning' });
    }
  }
});
