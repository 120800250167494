const state = {
	allNotifications: [],
	unreadNotifications: [],
	readNotifications: [],
	singleNotification: {}
}

const getters = {
	hasReadNotifications: (state) => !!state?.readNotifications?.length,
	hasUnreadNotifications: (state) => !!state?.unreadNotifications.length,
	getReadNotifications: (state) => state?.readNotifications,
	getUnreadNotifications: (state) => state?.unreadNotifications
}

const mutations = {
	getUnread: (state, payload) => {
		state.unreadNotifications = payload
	},
	getRead: (state, payload) => {
		state.readNotifications = payload
	},
	openNotification: (state, payload) => {
		state.singleNotification = payload
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}