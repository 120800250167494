<template>
  <nav class="navbar border-b border-credpal-300 h-20">

    <div class="sidebar-toggle md:hidden" @click.prevent="toggleSidebar">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>

    <template v-if="accountType === ENV_VUE_APP_CUSTOMER_PATH">
      <h1 class="font-bold hidden mt-2 text-sm md:block lg:text-xl">
        Good {{ timeOfDay }}, {{ firstName }}
      </h1>
    </template>

    <ul class="flex flex-row items-center ml-auto">
      <li class="h-full">
        <router-link :to="{name: 'notifications'}" class="relative flex items-center px-5 py-3" dropdown>
          <div class="relative px-4 py-4 border border-credpal-300 rounded-full items-center justify-center">
            <div
              class="absolute px-2 rounded-full ml-3 -mt-7 text-center font-bold"
              style="background: #FF295D; border: 1.4px solid #FFFFFF;"
              :class="$store.state.notifications.unreadNotifications.length === 0 ? 'hidden' : 'block'"
            >
              <span class="text-9 text-white">
                {{ $store.state.notifications.unreadNotifications.length || '' }}
              </span>
            </div>
            <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g opacity="0.6">
                <path d="M1.5875 6.625C1.5875 3.35961 4.23462 0.7125 7.5 0.7125C10.7654 0.7125 13.4125 3.35962 13.4125 6.625V11.9947H1.5875V6.625Z" stroke="#142877" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.31152 14.3223C5.8584 16.5591 9.13965 16.5591 9.68652 14.3223" stroke="#142877" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
            </svg>
          </div>
        </router-link>
      </li>

      <!-- <label
        class="flex flex-row items-center cursor-pointer py-2 px-4 border border-blue-200 rounded mr-3"
        style="user-select: none"
        v-if="canToggle"
      >
        <span class="text-xs text-gray-500 mr-3">
          <span class="duration-300" :class="{ 'text-sm font-bold text-black': staff_account_type == 'business' }">Business</span>
          /
          <span class="duration-300" :class="{ 'text-sm font-bold text-black': staff_account_type == 'personal' }">Personal</span>
        </span>
        <label class="custom-switch">
          <input type="checkbox" class="custom-switch-input" :checked="staff_account_type === 'personal'" @change="toggleAccountType">
          <span class="custom-switch-label"></span>
        </label>
      </label> -->

      <li class="h-full dropdown">
        <a
          href="#!"
          class="rounded-full p-2 pr-4 -ml-2 lg:mr-5 bg-transparent inline-flex items-center font-medium cursor-pointer"
          style="border: 1px solid #EDF0FF;"
          dropdown
        >
          <span
            class="w-30px h-30px mr-2 flex items-center justify-center bg-indigo-200 rounded-full text-white text-xs"
            style="flex: 0 0 30px"
          >
            {{ firstNameFirstLetter }}
          </span>
          <span class="hidden mr-2 text-gray-700 text-xs md:inline lg:text-10 lg:ml-1">
            {{ firstName }} {{ lastName }}
          </span>
          <span class="inline-flex text-gray-600">
            <ion-icon name="chevron-down-outline" />
          </span>
        </a>
        <ul class="dropdown-menu right-0 mt-2" @click="closeDropdown">
          <router-link
            :to="{ name: 'dashboard', params: { accountType: ENV_VUE_APP_ADMIN_PATH } }"
            class="dropdown-menu-item text-blue-500"
            v-if="isCredpalAdmin && accountType !== ENV_VUE_APP_ADMIN_PATH"
          >
            <ion-icon name="log-out-outline" class="icon"></ion-icon>
            <span class="text">Switch to Admin Account</span>
          </router-link>
          <router-link
            :to="{ name: 'dashboard', params: { accountType: ENV_VUE_APP_CORPORATE_PATH } }"
            class="dropdown-menu-item text-blue-500"
            v-if="isCompanyAdmin && accountType !== ENV_VUE_APP_CORPORATE_PATH"
          >
            <ion-icon name="log-out-outline" class="icon"></ion-icon>
            <span class="text">Switch to Corporate Account</span>
          </router-link>
          <router-link
            :to="{ name: 'dashboard', params: { accountType: ENV_VUE_APP_CUSTOMER_PATH } }"
            class="dropdown-menu-item text-blue-500"
            v-if="isCompanyStaff && accountType !== ENV_VUE_APP_CUSTOMER_PATH"
          >
            <ion-icon name="log-out-outline" class="icon"></ion-icon>
            <span class="text">Switch to Customer Account</span>
          </router-link>
          <router-link :to="{ name: 'logout' }" class="dropdown-menu-item text-red-500">
            <ion-icon name="log-out-outline" class="icon"></ion-icon>
            <span class="text">Log Out</span>
          </router-link>
        </ul>
      </li>
    </ul>

  </nav>
</template>

<script>
  import { mapMutations, mapState } from 'vuex';
  import $ from 'jquery';

  export default {
    computed: {
      ...mapState('design', ['sidebar']),
      ...mapState('session', ['staff_account_type', 'corporate_card_count']),
      canToggle() {
        return this.accountType == this.ENV_VUE_APP_CUSTOMER_PATH && this.user?.company_id;
      },
      firstName() {
        return this.user?.name;
      },
      firstNameFirstLetter() {
        return this.firstName?.[0];
      },
    },
    methods: {
      ...mapMutations('design', ['toggleSidebar']),
      closeDropdown(event) {
        $(event.target)
          .closest('.dropdown')
          .removeClass('open')
          .find('.dropdown')
          .removeClass('top bottom');
      },
      logout() {
        this.$store.dispatch('session/logout');
        this.$router.push({ name: 'login' });
      },
      toggleAccountType() {
        this.$store.dispatch(
          'session/updateStaffAccountType',
          this.staff_account_type == 'personal' ? 'business' : 'personal'
        );
      }
    }
  }
</script>