import Vue from 'vue';

Vue.mixin({
  methods: {

    formatAmount(x=0) {
      x = Number(x);
      return '₦'+x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    googleEvent(category, action) {
      //window.ga("send", "event", category, action)
      console.log(category, action)
    },
    goTo(name) {
      return this.$router.push({
        name: name
      })
    },
    confirmDocuments(){

      let work_id = (this.$store.state.session.user.documents.find(el => el.type == 'work_id') || false) && true;
      if (this.$store.state.session.user?.profile?.employment_status != 'employed') {
        console.log('is self employed')
        work_id = true;
      }
      
      const govt_id = (this.$store.state.session.user.documents.find(el => el.type == 'govt_id') || false) && true;

      //const bank_statement = (this.$store.state.session.user.documents.find(el => el.type == 'bank_statement') || false) && true;
      
      console.log(work_id)
      return !! work_id && govt_id;
      // && bank_statement

    },
    getMaxNumberFromCollection(data, key) {
      return data.reduce((max, p) => p[key] > max ? p[key] : max, data[0][key]);
    },
    addCommaToNumber(str) {
      str = str+'';
      return str.replace(/(.)(?=(.{3})+$)/g,"$1,").replace(',.', '.') || '';
    },
    getCardType(number) {
      // visa
      var re = new RegExp("^4");
      if (number.match(re) != null) {
        return "Visa";
      }

      // Mastercard 
      // Updated for Mastercard 2017 BINs expansion
      if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number))  {
        return "Mastercard";
      }

      // AMEX
      re = new RegExp("^3[47]");
      if (number.match(re) != null) {
        return "AMEX";
      }

      // Discover
      re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
      if (number.match(re) != null) {
        return "Discover";
      }

      // Diners
      re = new RegExp("^36");
      if (number.match(re) != null) {
        return "Diners";
      }

      // Diners - Carte Blanche
      re = new RegExp("^30[0-5]");
      if (number.match(re) != null) {
        return "Diners - Carte Blanche";
      }

      // JCB
      re = new RegExp("^35(2[89]|[3-8][0-9])");
      if (number.match(re) != null) {
        return "JCB";
      }

      // Visa Electron
      re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
      if (number.match(re) != null) {
        return "Visa Electron";
      }

      return "";
    },
  }
});