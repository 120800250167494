export default {
  auth(to, from, next) {
    const session = this.$store.state.session;

    if (!session.loggedIn) {
      // console.log('not logged in')
      return next({ name: "login" });
    }

    return true;
  },
  guest(to, from, next) {
    if (this.$store.state.session.loggedIn) return next({ name: 'root' })
    return true
  },
  password_changed(to, from, next) {
    if (!this.user?.change_password_at) {
      return next({ name: "update-password" });
    }
    return true;
  },
  profile_updated(to, from, next) {
    switch (to.params.accountType) {
      case process.env.VUE_APP_CORPORATE_PATH:
        if (!this.user?.company?.profile) {
          return next({
            name: "onboarding",
            params: { accountType: process.env.VUE_APP_CORPORATE_PATH },
          });
        }
        break;
      case process.env.VUE_APP_CUSTOMER_PATH:
        // if (
        //   this.$store.state.session.staff_account_type == "personal" &&
        //   !this.validateUser("bio") &&
        //   !this.validateUser("documents")
        // ) {
        //   return next({
        //     name: "settings-profile",
        //     params: { accountType: process.env.VUE_APP_CUSTOMER_PATH },
        //   });
        // }
        break;
      case process.env.VUE_APP_ADMIN_PATH:
        break;
      default:
        break;
    }

    return true;
  },
  profile_approved(to, from, next) {
    switch (to.params.accountType) {
      case process.env.VUE_APP_CORPORATE_PATH:
        if (this.user?.company?.profile !== "approved") {
          // return next({ name: 'awaiting-approval', params: { accountType: 'corporate' } });
        }
        break;
      case process.env.VUE_APP_CUSTOMER_PATH:
        // if (
        //   (!this.user?.can_have_debit &&
        //     this.user.profile?.status !== "activated") ||
        //   !this.confirmDocuments()
        // ) {
        //   return next({
        //     name: "awaiting-approval",
        //     params: { accountType: process.env.VUE_APP_CUSTOMER_PATH },
        //   });
        // }
        from, next;
        break;
      case process.env.VUE_APP_ADMIN_PATH:
        break;
      default:
        break;
    }

    return true;
  },
  profile_declined(to, from, next) {
    switch (to.params.accountType) {
      case process.env.VUE_APP_CORPORATE_PATH:
        if (this.user?.company?.profile !== "approved") {
          // return next({ name: 'awaiting-approval', params: { accountType: 'corporate' } });
        }
        break;
      case process.env.VUE_APP_CUSTOMER_PATH:
        if (this.user.profile?.status === "declined") {
          return next({
            name: "awaiting-approval",
            params: { accountType: process.env.VUE_APP_CUSTOMER_PATH },
          });
        }
        break;
      case process.env.VUE_APP_ADMIN_PATH:
        break;
      default:
        break;
    }
  },
  company_subscribed(to, from, next) {
    if (to.params.accountType == process.env.VUE_APP_CORPORATE_PATH) {
      if (!this.user?.company?.company_unique_plan) {
        return next({
          name: "subscription",
          params: { accountType: process.env.VUE_APP_CORPORATE_PATH },
        });
      }
    }

    return true;
  },
  permissions(to, from, next) {
    if (!this.canAccess(to.name)) {
      console.log("cannot access " + to.name);
      return next({
        name: "root",
        query: { accountType: to.params.accountType },
      });
    }

    return true;
  },
  account_type(to, from, next) {
    const requiredAccountType = to.params.accountType;

    switch (requiredAccountType) {
      case process.env.VUE_APP_ADMIN_PATH:
        if (!this.isCredpalAdmin) {
          return next({
            name: "errors-404-main",
            query: { permission: false },
          });
        }
        break;
      case process.env.VUE_APP_CORPORATE_PATH:
        if (!this.isCompanyAdmin) {
          return next({
            name: "errors-404-main",
            query: { permission: false },
          });
        }
        break;
      case process.env.VUE_APP_CUSTOMER_PATH:
        if (!this.isCompanyStaff) {
          return next({
            name: "errors-404-main",
            query: { permission: false },
          });
        }
        break;
      default:
        break;
    }

    return true;
  },
  downloadApp(to, from, next) {
    console.log(to.meta.allowEndUsers);
    const isDownloadPage = to.name === 'download';
    const isPageAllowed = to.meta.allowEndUsers;
    const isLiveServer = window.location.host.match(/^(www\.)?app\.credpal\.com/);
    const isProduction = process.env.VUE_APP_ENV?.match(/prod/);

    if (isProduction && isLiveServer && !isDownloadPage && !isPageAllowed) {
      return next({
        name: 'download',
      });
    }

    return true;
  },
  // super_admin(to, from, next) {

  //  if (!this.role.match(/super_(admin|sales_agent)/)) {
  //    return next({ name: 'dashboard' });
  //  }

  //  return true;
  // }
};
