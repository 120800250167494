var admin = process.env.VUE_APP_ADMIN_PATH;
var corporate = process.env.VUE_APP_CORPORATE_PATH;
var customer = process.env.VUE_APP_CUSTOMER_PATH;
const accountTypes = `${admin}|${corporate}|${customer}`;

function path(uri, ...types) {
  if (types.indexOf('*') > -1) {
    types = [admin, corporate, customer];
  }

  var url = `/:accountType(${types.join('|')})/${uri}`;

  return url;
}

export default {
  'root': {
    route: {
      name: 'root',
      path: '/',
      parent: 'root',
      component: () => import('@/views/Root.vue'),
      meta: {
        title: 'Corporate Loans'
      }
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['*']
  },
  'download': {
    route: {
      name: 'download',
      path: '/download',
      parent: 'root',
      component: () => import('@/views/misc/Download.vue'),
      meta: {
        title: 'Download the CredPal app',
        allowEndUsers: true
      }
    },
    middlewares: [],
    permissions: ['*']
  },

  'awaiting-approval': {
    route: {
      name: 'awaiting-approval',
      path: path('approval', customer, corporate),
      parent: 'app',
      component: () => import('@/views/app/awaiting-approval/Index.vue'),
      meta: {
        title: 'Awaiting Approval'
      }
    },
    middlewares: ['auth'],
    permissions: ['*']
  },

  'dashboard': {
    route: {
      name: 'dashboard',
      path: path('dashboard', '*'),
      parent: 'app',
      component: () => import('@/views/app/dashboard/Index.vue'),
      meta: {
        title: 'Dashboard'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },

  'offer-letter': {
    route: {
      name: 'offer-letter',
      path: path('offer-letter/:loanId', admin, customer),
      parent: 'root',
      component: () => import( /* webpackChunkName: 'offer-letter' */ '@/views/app/offer-letter/Index.vue'),
      meta: {
        title: 'Offer Letter'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_view_offer_letter', 'personal:can_view_offer_letter']
  },

  'bank-accounts': {
    route: {
      name: 'bank-accounts',
      path: path('bank-accounts', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'bank-accounts' */ '@/views/app/bank-accounts/Index.vue'),
      meta: {
        title: 'Bank Account Verification & Bypass',
      }
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_bank_accounts']
  },
  'bank-accounts-pending': {
    route: {
      name: 'bank-accounts-pending',
      path: path('bank-accounts-pending', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'bank-accounts-pending' */ '@/views/app/bank-accounts/Pending.vue'),
      meta: {
        title: 'Bank Account Verification & Bypass',
      }
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_verify_bank_accounts']
  },

  'identity-verifications': {
    route: {
      name: 'identity-verifications',
      path: path('identity-verifications', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'identity-verifications' */ '@/views/app/identity-verifications/Index.vue'),
      meta: {
        title: 'Identity Verifications',
      }
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_identity_verifications']
  },

  'loanbot-verifications': {
    route: {
      name: 'loanbot-verifications',
      path: path('loanbot-verifications', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'loanbot-verifications' */ '@/views/app/loanbot-verifications/Index.vue'),
      meta: {
        title: 'Loanbot Verifications',
      }
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_loanbot_verifications', 'credpal:can_use_loanbot_verifications']
  },

  'users': {
    route: {
      name: 'users',
      path: path('users', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'users-index' */ '@/views/app/users/Index.vue'),
      meta: {
        title: 'Users'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'users-paid': {
    route: {
      name: 'users-paid',
      path: path('users/paid', admin),
      parent: 'app',
      component: () => import(/*webpackChunkName: 'users-paid'*/ '@/views/app/users/Paid.vue'),
      meta: {
        title: 'Users'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'users-active': {
    route: {
      name: 'users-active',
      path: path('users/active', admin),
      parent: 'app',
      component: () => import(/*webpackChunkName: 'users-active'*/ '@/views/app/users/Active.vue'),
      meta: {
        title: 'Users'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'users-inactive': {
    route: {
      name: 'users-inactive',
      path: path('users/inactive', admin),
      parent: 'app',
      component: () => import(/*webpackChunkName: 'users-inactive'*/ '@/views/app/users/Inactive.vue'),
      meta: {
        title: 'Users'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'users-fresh': {
    route: {
      name: 'users-fresh',
      path: path('users/fresh', admin),
      parent: 'app',
      component: () => import(/*webpackChunkName: 'users-fresh'*/ '@/views/app/users/leads/Fresh.vue'),
      meta: {
        title: 'New users'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'users-awaiting': {
    route: {
      name: 'users-awaiting',
      path: path('users/awaiting', admin),
      parent: 'app',
      component: () => import(/*webpackChunkName: 'users-awaiting'*/ '@/views/app/users/leads/Awaiting.vue'),
      meta: {
        title: 'New users'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'users-processing': {
    route: {
      name: 'users-processing',
      path: path('users/processing', admin),
      parent: 'app',
      component: () => import(/*webpackChunkName: 'users-processing'*/ '@/views/app/users/Processing.vue'),
      meta: {
        title: 'New users'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'users-cold': {
    route: {
      name: 'users-cold',
      path: path('users/cold', admin),
      parent: 'app',
      component: () => import(/*webpackChunkName: 'users-cold'*/ '@/views/app/users/leads/Cold.vue'),
      meta: {
        title: 'New users'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'users-dump': {
    route: {
      name: 'users-dump',
      path: path('users/dump', admin),
      parent: 'app',
      component: () => import(/*webpackChunkName: 'users-dump'*/ '@/views/app/users/leads/Dump.vue'),
      meta: {
        title: 'New users'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'users-leads-statistics': {
    route: {
      name: 'users-leads-statistics',
      path: path('users/leads/statistics', admin),
      parent: 'app',
      component: () => import(/*webpackChunkName: 'users-leads-statistics'*/ '@/views/app/users/leads/Statistics.vue'),
      meta: {
        title: 'New users'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'users-active-inactive-statistics': {
    route: {
      name: 'users-active-inactive-statistics',
      path: path('users/active/statistics', admin),
      parent: 'app',
      component: () => import(/*webpackChunkName: 'users-active-inactive-statistics'*/ '@/views/app/users/Statistics.vue'),
      meta: {
        title: 'Active User Statistics'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'users-new': {
    route: {
      name: 'users-new',
      path: path('users/new', admin),
      parent: 'app',
      component: () => import(/*webpackChunkName: 'users-new'*/ '@/views/app/users/new/Index.vue'),
      meta: {
        title: 'Create a user'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'users-assigned': {
    route: {
      name: 'users-assigned',
      path: path('users/assigned', admin),
      parent: 'app',
      component: () => import(/*webpackChunkName: 'users-assigned'*/ '@/views/app/users/assigned/Index.vue'),
      meta: {
        title: 'Users assigned to me'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },

  'users-approved': {
    route: {
      name: 'users-approved',
      path: path('users/approved', admin),
      parent: 'app',
      component: () => import(/* webpackChunkName: 'users-approved' */ '@/views/app/users/Approved.vue'),
      meta: {
        title: 'Approved users'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },

  'users-pre-approved': {
    route: {
      name: 'users-pre-approved',
      path: path('users/pre-approved', admin),
      parent: 'app',
      component: () => import(/* webpackChunkName: 'users-pre-approved' */ '@/views/app/users/pre-approved/Index.vue'),
      meta: {
        title: 'Pre-approved users'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },

  'users-merchant-pre-approved': {
    route: {
      name: 'users-merchant-pre-approved',
      path: path('users/merchant/pre-approved', admin),
      parent: 'app',
      component: () => import(/* webpackChunkName: 'users-merchant-pre-approved' */ '@/views/app/users/merchant/Index.vue'),
      meta: {
        title: 'Merchant Pre-approved users'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },

  'users-incomplete': {
    route: {
      name: 'users-incomplete',
      path: path('users/incomplete', admin),
      parent: 'app',
      component: () => import(/* webpackChunkName: 'users-incomplete' */ '@/views/app/users/incomplete/Index.vue'),
      meta: {
        title: 'Incomplete applications'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },

  'users-declined': {
    route: {
      name: 'users-declined',
      path: path('users/declined', admin),
      parent: 'app',
      component: () => import(/* webpackChunkName: 'users-declined' */ '@/views/app/users/Declined.vue'),
      meta: {
        title: 'Declined credit applications'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },

  'users-limit-increase-request': {
    route: {
      name: 'users-limit-increase-request',
      path: path('users/limit-increase-request', admin),
      parent: 'app',
      component: () => import(/* webpackChunkName: 'users-limit-increase-request' */ '@/views/app/users/limit-increase/Index.vue'),
      meta: {
        title: 'Limit Increase Requests'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },

  'users-uncertain': {
     route: {
       name: 'users-uncertain',
       path: path('users/uncertain', admin),
       parent: 'app',
       component: () => import(/* webpackChunkName: 'users-uncertain' */ '@/views/app/users/Uncertain.vue'),
       meta: {
         title: 'View uncertain users'
       }
     },
     middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
     permissions: ['*']
  },
  'users-onboarding': {
    route: {
      name: 'users-onboarding',
      path: path('users/onboarding', admin),
      parent: 'app',
      component: () => import(/* webpackChunkName: 'users-onboarding' */ '@/views/app/users/onboarding/Index.vue'),
      meta: {
        title: 'Complete user\'s onboarding'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'users-highend': {
     route: {
       name: 'users-highend',
       path: path('users/highend', admin),
       parent: 'app',
       component: () => import(/* webpackChunkName: 'users-highend' */ '@/views/app/users/Highend.vue'),
       meta: {
         title: 'View highend users'
       }
     },
     middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
     permissions: ['credpal:can_view_lead_qualifications']
  },

  'users-applications': {
    route: {
      name: 'users-applications',
      path: path('users/applications', admin),
      parent: 'app',
      component: () => import(/*webpackChunkName: 'users-applications'*/ '@/views/app/users/Applications.vue'),
      meta: {
        title: 'Applications'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'users-post-no-debit': {
    route: {
      name: 'users-post-no-debit',
      path: path('users/post-no-debit', admin),
      parent: 'app',
      component: () => import(/*webpackChunkName: 'users-applications'*/ '@/views/app/users/PostNoDebit.vue'),
      meta: {
        title: 'Post No Debit'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'users-appeal-requests': {
    route: {
      name: 'users-appeal-requests',
      path: path('users/appeal-requests', admin),
      parent: 'app',
      component: () => import(/*webpackChunkName: 'users-applications'*/ '@/views/app/users/appeal-requests/Index.vue'),
      meta: {
        title: 'Appeal Requests'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'bank-statements': {
    route: {
      name: 'bank-statements',
      path: path('users/bank-statements', admin),
      parent: 'app',
      component: () => import(/*webpackChunkName: 'bank-statements'*/ '@/views/app/users/BankStatement.vue'),
      meta: {
        title: 'Bank Statement'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },

  'verification-method': {
    route: {
      name: 'verification-method',
      path: path('users/verification-method', admin),
      parent: 'app',
      component: () => import(/*webpackChunkName: 'users-verification-method'*/ '@/views/app/users/verification/Index.vue'),
      meta: {
        title: 'User Verification Method'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },

  'lenders': {
    route: {
      name: 'lenders',
      path: path('lenders', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'lenders-index' */ '@/views/app/lenders/Index.vue'),
      meta: {
        title: 'Dashboard'
      }
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_lenders', 'credpal:can_delete_lenders']
  },
  'lenders-new': {
    route: {
      name: 'lenders-new',
      path: path('lenders/new', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'lenders-new' */ '@/views/app/lenders/New.vue'),
      meta: {
        title: 'Dashboard'
      }
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_lenders', 'credpal:can_create_lenders']
  },

  'wallet': {
    route: {
      name: 'wallet',
      path: path(`wallet`, corporate),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'wallet-index' */ '@/views/app/wallet/Index.vue'),
      meta: {
        title: 'Wallet',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_wallet', 'credpal:can_fund_debit_wallet', 'credpal:can_apply_for_credit',
      'company:can_view_wallet', 'company:can_fund_debit_wallet', 'company:can_apply_for_credit'
    ]
  },

  'analytics': {
    route: {
      name: 'analytics',
      path: path(`analytics`, corporate),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'analytics-index' */ '@/views/app/analytics/Index.vue'),
      meta: {
        title: 'Account Summary',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['company:can_view_analytics']
  },

  'expenses': {
    route: {
      name: 'expenses',
      path: `/:accountType(${accountTypes})/expenses`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'expenses-index' */ '@/views/app/expenses/all/Index.vue'),
      meta: {
        title: 'Expenses',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_expenses', 'credpal:can_flag_expenses', 'credpal:can_approve_expenses', 'credpal:can_delete_expenses',
      'company:can_view_expenses', 'company:can_flag_expenses', 'company:can_approve_expenses', 'company:can_delete_expenses',
      'personal:can_view_expenses'
    ]
  },
  'expense-requests': {
    route: {
      name: 'expense-requests',
      path: `/:accountType(${accountTypes})/expenses/requests`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'expense-requests' */ '@/views/app/expenses/requests/Index.vue'),
      meta: {
        title: 'Expense Requests',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'company:can_view_expense_requests', 'company:can_approve_expense_requests', 'company:can_decline_expense_requests', 'company:can_delete_expense_requests',
    ]
  },
  'expense-policies': {
    route: {
      name: 'expense-policies',
      path: `/:accountType(${accountTypes})/expenses/policies`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'expense-policies' */ '@/views/app/expense-policies/all/Index.vue'),
      meta: {
        title: 'Expense Policies',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'company:can_view_expense_policies', 'company:can_create_expense_policies', 'company:can_update_expense_policies', 'company:can_delete_expense_policies',
    ]
  },
  'expense-policies-new': {
    route: {
      name: 'expense-policies-new',
      path: `/:accountType(${accountTypes})/expenses/policies/new`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'expense-policies-new' */ '@/views/app/expense-policies/new/Index.vue'),
      meta: {
        title: 'Expense Policies',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'company:can_create_expense_policies'
    ]
  },

  'transfers': {
    route: {
      name: 'transfers',
      path: `/:accountType(${accountTypes})/transfers`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'transfers-index' */ '@/views/app/transfers/all/Index.vue'),
      meta: {
        title: 'Transfers',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'company:can_view_expenses', 'company:can_flag_expenses', 'company:can_approve_expenses', 'company:can_delete_expenses',
    ]
  },

  'statement': {
    route: {
      name: 'statement',
      path: `/:accountType(${accountTypes})/statement`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'statements-index' */ '@/views/app/statements/all/Index.vue'),
      meta: {
        title: 'Statement',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_statements', 'credpal:can_delete_statements',
      'company:can_view_statements', 'company:can_pay_statements',
      'personal:can_view_statements', 'personal:can_pay_statements'
    ]
  },

  'settlements': {
    route: {
      name: 'settlements',
      path: `/:accountType(${accountTypes})/settlements`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'settlements-index' */ '@/views/app/settlements/all/Index.vue'),
      meta: {
        title: 'Settlements',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_settlements', 'credpal:can_delete_settlements'
    ]
  },
  'settlements-due': {
    route: {
      name: 'settlements-due',
      path: `/:accountType(${accountTypes})/settlements/due`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'settlements-due' */ '@/views/app/settlements/due/Index.vue'),
      meta: {
        title: 'Settlements',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['can_view_settlements', 'can_pay_settlements', 'can_delete_settlements']
  },

  'repayments': {
    route: {
      name: 'repayments',
      path: path('repayments', admin, customer),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'repayments-index' */ '@/views/app/repayments/all/Index.vue'),
      meta: {
        title: 'Repayments',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_repayments', 'credpal:can_view_loan_repayments', 'credpal:can_delete_repayments',
      'personal:can_view_repayments', 'personal:can_pay_repayments'
    ]
  },
  'payments-credit-cards': {
    route: {
      name: 'payments-credit-cards',
      path: path('repayments/credit-cards/payments', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'repayments-credit-cards' */ '@/views/app/repayments/credit-cards/Index.vue'),
      meta: {
        title: 'Credit Card Payments',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_repayments', 'credpal:can_delete_repayments'
    ]
  },
  'repayments-credit-cards': {
    route: {
      name: 'repayments-credit-cards',
      path: path('repayments/credit-cards', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'repayments-credit-cards' */ '@/views/app/repayments/credit-cards-repayments/Super.vue'),
      meta: {
        title: 'Credit Card Repayments',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_repayments', 'credpal:can_view_credit_card_repayments', 'credpal:can_delete_repayments'
    ]
  },
  'repayments-business': {
    route: {
      name: 'repayments-business',
      path: path('repayments/business', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'repayments-business' */ '@/views/app/repayments/business/Index.vue'),
      meta: {
        title: 'Repayments',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_repayments', 'credpal:can_delete_repayments'
    ]
  },
  'settle-repayments': {
    route: {
      name: 'settle-repayments',
      path: path('repayments/settle', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'settle-repayments' */ '@/views/app/repayments/settle-repayments/Index.vue'),
      meta: {
        title: 'Repayments',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_settle_repayment']
  },
  'repayments-statistics': {
    route: {
      name: 'repayments-statistics',
      path: path('repayments/statistics', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'repayments-statistics' */ '@/views/app/repayments/statistics/Index.vue'),
      meta: {
        title: 'Repayments',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_repayments', 'credpal:can_delete_repayments'
    ]
  },
  'repayments-defaulters': {
    route: {
      name: 'repayments-defaulters',
      path: path('repayments/defaulters', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'repayments-defaulters' */ '@/views/app/repayments/defaulters/Index.vue'),
      meta: {
        title: 'Loan Defaulters',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_loans', 'company:can_view_loans', 'personal:can_view_loans'
    ]
  },
  'repayment-cards': {
    route: {
      name: 'repayment-cards',
      path: `/:accountType(${accountTypes})/repayment-cards`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'repayment-cards' */ '@/views/app/repayment-cards/Index.vue'),
      meta: {
        title: 'Repayment Cards',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_repayment_cards', 'credpal:can_create_repayment_cards', 'credpal:can_update_repayment_cards', 'credpal:can_delete_repayment_cards',
      'personal:can_view_repayment_cards', 'personal:can_create_repayment_cards', 'personal:can_update_repayment_cards', 'personal:can_delete_repayment_cards'
    ]
  },

  'payments': {
    route: {
      name: 'payments',
      path: `/:accountType(${accountTypes})/payments`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'payments' */ '@/views/app/payments/all/Index.vue'),
      meta: {
        title: 'Payments',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'payment-history': {
    route: {
      name: 'payment-history',
      path: `/:accountType(${accountTypes})/payments/history`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'payment-history' */ '@/views/app/payments/history/Index.vue'),
      meta: {
        title: 'Payment History',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },

  'admins': {
    route: {
      name: 'admins',
      path: `/:accountType(${accountTypes})/admins`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'admins-index' */ '@/views/app/admins/all/Index.vue'),
      meta: {
        title: 'All Admins',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_admins', 'credpal:can_update_admins', 'credpal:can_delete_admins',
      'company:can_view_admins', 'company:can_update_admins', 'company:can_delete_admins'
    ]
  },
  'admins-new': {
    route: {
      name: 'admins-new',
      path: `/:accountType(${accountTypes})/admins/new`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'admins-index' */ '@/views/app/admins/new/Index.vue'),
      meta: {
        title: 'New Admin',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_create_admins', 'company:can_create_admins'
    ]
  },

  'activities': {
    route: {
      name: 'activities',
      path: `/:accountType(${accountTypes})/activities`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'activities-index' */ '@/views/app/activities/all/Index.vue'),
      meta: {
        title: 'Activities',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_activities', 'company:can_view_activities', 'personal:can_view_activities'
    ]
  },
  'activities-recent': {
    route: {
      name: 'activities-recent',
      path: `/:accountType(${accountTypes})/activities/recent`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'activities-recent' */ '@/views/app/activities/recent/Index.vue'),
      meta: {
        title: 'Recent Activities',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_activities', 'company:can_view_activities', 'personal:can_view_activities'
    ]
  },

  'loans': {
    route: {
      name: 'loans',
      path: path('loans', admin, customer),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'loans-index' */ '@/views/app/loans/all/Index.vue'),
      meta: {
        title: 'All Loans',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_loans', 'company:can_view_loans', 'personal:can_view_loans'
    ]
  },
  'loans-pending': {
    route: {
      name: 'loans-pending',
      path: path('loans/pending', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'loans-pending' */ '@/views/app/loans/pending/Index.vue'),
      meta: {
        title: 'Pending Loans',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_loans', 'company:can_view_loans', 'personal:can_view_loans'
    ]
  },
  'loans-requests': {
    route: {
      name: 'loans-requests',
      path: path('loans/requests', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'loans-requests' */ '@/views/app/loans/Requests.vue'),
      meta: {
        title: 'Loan Requests',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_view_loans', 'credpal:can_approve_loans']
  },
  'loans-business': {
    route: {
      name: 'loans-business',
      path: path('loans/business', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'loans-business' */ '@/views/app/loans/business/Index.vue'),
      meta: {
        title: 'Loan Requests',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_view_loans', 'credpal:can_approve_loans']
  },
  'employee-loan-history': {
    route: {
      name: 'employee-loan-history',
      path: `/:accountType(${accountTypes})/employee-loan-history`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'employee-loan-history' */ '@/views/app/loan_history/Employee.vue'),
      meta: {
        title: 'Employee Loan History',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['company:can_view_loans']
  },

  'disbursement-statistics': {
    route: {
      name: 'disbursement-statistics',
      path: path('disbursement/statistics', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'disbursement-statistics' */ '@/views/app/disbursement/statistics/Index.vue'),
      meta: {
        title: 'Disbursement',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_loan_disbursements', 'credpal:can_disburse_loans', 'credpal:can_undisburse_loans',
      'company:can_view_loan_disbursements',
      'personal:can_view_loan_disbursements'
    ]
  },
  'disbursement-personal-loans': {
    route: {
      name: 'disbursement-personal-loans',
      path: path('disbursement/personal/loans', admin, customer),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'disbursement-personal-loans' */ '@/views/app/disbursement/personal-loans/Index.vue'),
      meta: {
        title: 'Disbursement',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_loan_disbursements', 'credpal:can_disburse_loans', 'credpal:can_undisburse_loans',
      'company:can_view_loan_disbursements',
      'personal:can_view_loan_disbursements'
    ]
  },
  'disbursement-credit-card-loans': {
    route: {
      name: 'disbursement-credit-card-loans',
      path: path('disbursement/credit-card/loans', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'disbursement-credit-card-loans' */ '@/views/app/disbursement/credit-card-loans/Index.vue'),
      meta: {
        title: 'Disbursement',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_loan_disbursements', 'credpal:can_disburse_loans', 'credpal:can_undisburse_loans',
      'company:can_view_loan_disbursements',
      'personal:can_view_loan_disbursements'
    ]
  },
  'disbursement-business-loans': {
    route: {
      name: 'disbursement-business-loans',
      path: path('disbursement/business/loans', admin, corporate),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'disbursement-business-loans' */ '@/views/app/disbursement/business-loans/Index.vue'),
      meta: {
        title: 'Disbursement',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_loan_disbursements', 'credpal:can_disburse_loans', 'credpal:can_undisburse_loans',
      'company:can_view_loan_disbursements',
      'personal:can_view_loan_disbursements'
    ]
  },

  'credit-application': {
    route: {
      name: 'credit-application',
      path: `/:accountType(${accountTypes})/credit-application`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'credit-application' */ '@/views/app/credit-application/Index.vue'),
      meta: {
        title: 'Apply For Credit',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['company:can_apply_for_credit', 'personal:can_apply_for_credit']
  },
  'companies-credit': {
    route: {
      name: 'companies-credit',
      path: path('companies/credit-applications', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'companies-credit' */ '@/views/app/companies/Credit.vue'),
      meta: {
        title: 'Credit Applications',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_view_credit_applications', 'credpal:can_approve_credit_applications', 'credpal:can_decline_credit_applications']
  },

  'subscription': {
    route: {
      name: 'subscription',
      path: `/:accountType(${accountTypes})/subscription`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'subscription' */ '@/views/app/subscription/subscribe/Index.vue'),
      meta: {
        title: 'Subscribe',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated'],
    permissions: [
      'company:can_view_subscription_plans', 'company:can_subscribe_to_plan',
      'personal:can_view_subscription_plans', 'personal:can_subscribe_to_plan'
    ]
  },

  'subscription-upgrade': {
    route: {
      name: 'subscription-upgrade',
      path: `/:accountType(${accountTypes})/subscription/upgrade`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'subscription-upgrade' */ '@/views/app/subscription/subscribe/Index.vue'),
      meta: {
        title: 'Subscribe',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'company:can_view_subscription_plans', 'company:can_subscribe_to_plan',
      'personal:can_view_subscription_plans', 'personal:can_subscribe_to_plan'
    ]
  },

  'companies': {
    route: {
      name: 'companies',
      path: `/:accountType(${accountTypes})/companies`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'companies-index' */ '@/views/app/companies/Index.vue'),
      meta: {
        title: 'Companies',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_view_companies', 'credpal:can_update_companies']
  },
  'companies-requests': {
    route: {
      name: 'companies-requests',
      path: `/:accountType(${accountTypes})/companies/requests`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'companies-requests' */ '@/views/app/companies/Requests.vue'),
      meta: {
        title: 'Company Applications',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_view_company_requests', 'credpal:can_approve_company_requests', 'credpal:can_decline_company_requests']
  },
  'companies-users': {
    route: {
      name: 'companies-users',
      path: `/:accountType(${accountTypes})/companies/users/:company_id`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'companies-users' */ '@/views/app/companies/Users.vue'),
      meta: {
        title: 'Company Users',
      }
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_view_staff']
  },
  'companies-new': {
    route: {
      name: 'companies-new',
      path: `/:accountType(${accountTypes})/companies/new`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'companies-new' */ '@/views/app/companies/New.vue'),
      meta: {
        title: 'Register A New Company',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_create_companies']
  },
  'companies-statistics': {
    route: {
      name: 'companies-statistics',
      path: `/:accountType(${accountTypes})/companies/statistics`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'companies-statistics' */ '@/views/app/companies/Statistics.vue'),
      meta: {
        title: 'Register A New Company',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_create_companies']
  },

  'groups': {
    route: {
      name: 'groups',
      path: `/:accountType(${accountTypes})/groups`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'groups-index' */ '@/views/app/groups/all/Index.vue'),
      meta: {
        title: 'Departments',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['company:can_view_departments', 'company:can_update_departments', 'company:can_delete_departments']
  },
  'groups-new': {
    route: {
      name: 'groups-new',
      path: `/:accountType(${accountTypes})/groups/new`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'groups-new' */ '@/views/app/groups/new/Index.vue'),
      meta: {
        title: 'New Group',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['company:can_create_departments']
  },

  'staff': {
    route: {
      name: 'staff',
      path: `/:accountType(${accountTypes})/staff`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'staff-index' */ '@/views/app/staff/all/Index.vue'),
      meta: {
        title: 'Employees',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_staff', 'credpal:can_update_staff', 'credpal:can_delete_staff',
      'company:can_view_staff', 'company:can_update_staff', 'company:can_delete_staff'
    ]
  },
  'staff-new': {
    route: {
      name: 'staff-new',
      path: `/:accountType(${accountTypes})/staff/new`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'staff-new' */ '@/views/app/staff/new/Index.vue'),
      meta: {
        title: 'Register An Employee',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_create_staff', 'company:can_create_staff']
  },
  'staff-view': {
    route: {
      name: 'staff-view',
      path: `/:accountType(${accountTypes})/users/:staffId`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'staff-view' */ '@/views/app/staff/view/Index.vue'),
      meta: {
        title: 'View Employees',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_view_staff_profile', 'company:can_view_staff_profile']
  },

  'cards': {
    route: {
      name: 'cards',
      path: path('cards', '*'),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'cards-index' */ '@/views/app/cards/all/Index.vue'),
      meta: {
        title: 'Cards',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_view_cards', 'credpal:can_delete_cards', 'company:can_view_cards', 'company:can_delete_cards', 'personal:can_view_cards']
  },
  'cards-new': {
    route: {
      name: 'cards-new',
      path: path('cards/new', corporate),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'cards-new' */ '@/views/app/cards/new/Index.vue'),
      meta: {
        title: 'Create New Card',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['company:can_request_cards']
  },
  'cards-request': {
    route: {
      name: 'cards-request',
      path: path('cards/request', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'cards-request' */ '@/views/app/cards/request/Index.vue'),
      meta: {
        title: 'Request A Cards',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_view_card_requests', 'credpal:can_approve_card_requests', 'credpal:can_decline_card_requests']
  },

  'cards-transaction': {
    route: {
      name: 'cards-transaction',
      path: path('cards/transaction', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'cards-transaction' */ '@/views/app/cards/transaction/Index.vue'),
      meta: {
        title: 'Cards Transaction',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_view_card_requests', 'credpal:can_approve_card_requests', 'credpal:can_decline_card_requests']
  },

  'beneficiaries': {
    route: {
      name: 'beneficiaries',
      path: path('beneficiaries', corporate),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'beneficiaries' */ '@/views/app/beneficiaries/all/Index.vue'),
      meta: {
        title: 'Beneficiaries',
      }
    },
    middlewares: ['auth', 'password_changed', 'company_profile_updated', 'company_subscribed'],
    permissions: ['company:can_view_beneficiaries', 'company:can_create_beneficiaries', 'company:can_update_beneficiaries', 'company:can_delete_beneficiaries']
  },
  'beneficiaries-new': {
    route: {
      name: 'beneficiaries-new',
      path: path('beneficiaries/new', corporate),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'beneficiaries-new' */ '@/views/app/beneficiaries/new/Index.vue'),
      meta: {
        title: 'Beneficiaries',
      }
    },
    middlewares: ['auth', 'password_changed', 'company_profile_updated', 'company_subscribed'],
    permissions: ['company:can_view_beneficiaries', 'company:can_create_beneficiaries', 'company:can_update_beneficiaries', 'company:can_delete_beneficiaries']
  },

  'permissions': {
    route: {
      name: 'permissions',
      path: path('permissions', admin, corporate),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'permissions' */ '@/views/app/permissions/Index.vue'),
      meta: {
        title: 'Permissions',
      }
    },
    middlewares: ['auth', 'password_changed', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_view_permissions', 'credpal:can_modify_permissions', 'company:can_view_permissions', 'company:can_modify_permissions']
  },

  'settings-profile': {
    route: {
      name: 'settings-profile',
      path: path(`settings/profile`, customer),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'settings-profile' */ '@/views/app/settings/profile/Index.vue'),
      meta: {
        title: 'Update Profile',
      }
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['personal:can_view_personal_profile', 'personal:can_update_personal_profile', 'personal:can_upload_personal_documents']
  },
  'settings-profile-company': {
    route: {
      name: 'settings-profile-company',
      path: path('settings/profile/company', corporate),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'settings-profile-company' */ '@/views/app/settings/CompanyProfile.vue'),
      meta: {
        title: 'Company Profile',
      }
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['company:can_view_company_profile', 'company:can_update_company_profile', 'company:can_upload_company_documents']
  },
  'settings-password': {
    route: {
      name: 'settings-password',
      path: path(`settings/password`, '*'),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'settings-password' */ '@/views/app/settings/Password.vue'),
      meta: {
        title: 'Change Your Password',
      }
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_update_password', 'company:can_update_password', 'personal:can_update_password']
  },
  'settings-configuration': {
    route: {
      name: 'settings-configuration',
      path: path('settings/configuration', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'settings-configuration' */ '@/views/app/settings/configuration/Index.vue'),
      meta: {
        title: 'Configurations',
      }
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_update_system_configuration']
  },
  'auth-bypass': {
    route: {
      name: 'auth-bypass',
      path: path('settings/bypass', admin),
      parent: 'app',
      component: () => import(/* webpackChunkName: 'auth-bypass' */'@/views/app/settings/Bypass.vue'),
      meta: {
        title: 'Authentication Bypass',
      }
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_update_system_configuration','credpal:can_edit_bypass']
  },
  'maintenance-mail': {
    route: {
      name: 'maintenance-mail',
      path: path('settings/mails', admin),
      parent: 'app',
      component: () => import(/* webpackChunkName: 'notifications' */'@/views/app/settings/MaintenanceMail.vue'),
      meta: {
        title: 'Maintenance Mails',
      }
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['credpal:can_update_system_configuration']
  },
  'notifications': {
    route: {
      name: 'notifications',
      path: path('notifications', '*'),
      parent: 'app',
      component: () => import(/* webpackChunkName: 'notifications' */'@/views/app/settings/Notifications.vue'),
      meta: {
        title: 'Notifications',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },

  'bills': {
    route: {
      name: 'bills',
      path: path('bills', customer),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'bills' */ '@/views/app/bills/Index.vue'),
      meta: {
        title: 'Airtime & Bills',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['company:can_view_bills', 'company:can_pay_bills', 'personal:can_view_bills', 'personal:can_pay_bills']
  },
  'marketplace': {
    route: {
      name: 'marketplace',
      path: path('marketplace', customer),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'marketplace' */ '@/views/app/marketplace/Index.vue'),
      meta: {
        title: 'Marketplace',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'vendors': {
    route: {
      name: 'vendors',
      path: path('vendors', customer),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'vendors' */ '@/views/app/marketplace/Vendor.vue'),
      meta: {
        title: 'All Vendors',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'vendors-category': {
    route: {
      name: 'vendors-category',
      path: path('vendor/category', customer),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'vendors-category' */ '@/views/app/marketplace/Category.vue'),
      meta: {
        title: 'All Vendors Category',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'vendors-deal': {
    route: {
      name: 'vendors-deal',
      path: path('vendor/deal', customer),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'vendors-deal' */ '@/views/app/marketplace/Deals.vue'),
      meta: {
        title: 'All Vendors Deal',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'category-vendors': {
    route: {
      name: 'category-vendors',
      path: path('category/:id/vendors', customer),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'category-vendors' */ '@/views/app/marketplace/CategoryVendors.vue'),
      meta: {
        title: 'All Category Vendors',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'order-history': {
    route: {
      name: 'order-history',
      path: path('order-history', customer),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'order-history' */ '@/views/app/marketplace/OrderHistory.vue'),
      meta: {
        title: 'Order History',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'bills-transactions': {
    route: {
      name: 'bills-transactions',
      path: path('bills-transactions', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'bills-transactions' */ '@/views/app/bills-transactions/Index.vue'),
      meta: {
        title: 'Airtime & Bills',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },

  'onboarding': {
    route: {
      name: 'onboarding',
      path: path('onboarding', corporate),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'onboarding' */ '@/views/app/onboarding/Index.vue'),
      meta: {
        title: 'Onboarding',
      }
    },
    middlewares: ['auth', 'password_changed'],
    permissions: ['company:can_view_onboarding']
  },
  'register-business': {
    route: {
      name: 'register-business',
      path: '/register/business',
      parent: 'register',
      component: () => import( /* webpackChunkName: 'register' */ '@/views/auth/register/business/Index.vue'),
      meta: {
        title: 'Create Your Account',
      }
    },
    middlewares: ['guest'],
    permissions: ['*']
  },
  'register': {
    route: {
      name: 'register',
      path: '/register/:source?',
      parent: 'register',
      component: () => import( /* webpackChunkName: 'register' */ '@/views/auth/register/personal/Index.vue'),
      meta: {
        title: 'Create Your Account',
      }
    },
    middlewares: ['guest'],
    permissions: ['*']
  },
  'login': {
    route: {
      name: 'login',
      path: '/login',
      parent: 'auth',
      component: () => import( /* webpackChunkName: 'login' */ '@/views/auth/Login.vue'),
      meta: {
        title: 'Login To Your Account',
      }
    },
    middlewares: ['guest'],
    permissions: ['*']
  },
  'logout': {
    route: {
      name: 'logout',
      path: '/logout',
      parent: 'auth',
      component: () => import('@/views/auth/Logout.vue'),
      meta: {
        title: 'Logging out...'
      }
    },
    middlewares: [],
    permissions: ['*']
  },
  'update-password': {
    route: {
      name: 'update-password',
      path: '/password/update',
      parent: 'auth',
      component: () => import( /* webpackChunkName: 'update-password' */ '@/views/auth/UpdatePassword.vue'),
      meta: {
        title: 'Update Your Password',
      }
    },
    middlewares: ['auth'],
    permissions: ['*']
  },
  'password-reset': {
    route: {
      name: 'password-reset',
      path: '/password/reset/:token',
      parent: 'auth',
      component: () => import( /* webpackChunkName: 'password-reset' */ '@/views/auth/ResetPassword.vue'),
      meta: {
        title: 'Reset your password',
        allowEndUsers: true
      }
    },
    middlewares: [],
    permissions: ['*']
  },
  'users-new-affiliate': {
    route: {
      name: 'users-new-affiliate',
      path: path('affiliate/register', admin),
      parent: 'app',
      component: () => import('@/views/app/affiliate/new/Index.vue'),
      meta: {
        title: 'New Affiliate User'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'all-affiliate-users': {
    route: {
      name: 'all-affiliate-users',
      path: path('affiliate/all', admin),
      parent: 'app',
      component: () => import('@/views/app/affiliate/all/AllAffiliateUser.vue'),
      meta: {
        title: 'Affiliate Users'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'referral-page': {
    route: {
      name: 'referral-page',
      path: path('affiliate/referrals', admin),
      parent: 'app',
      component: () => import('@/views/app/affiliate/referral/Index.vue'),
      meta: {
        title: 'Affiliate Users'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },

  'affiliate-view': {
    route: {
      name: 'affiliate-view',
      path: `/:accountType(${accountTypes})/affiliates/:userId`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'staff-index' */ '@/views/app/affiliate/view/Index.vue'),
      meta: {
        title: 'View Affiliate',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_view_staff_profile', 'company:can_view_staff_profile']
  },
  'affiliate-settlement': {
    route: {
      name: 'affiliate-settlement',
      path: `/:accountType(${accountTypes})/affiliate/settlements`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'settlements-index' */ '@/views/app/affiliate/settlements/Index.vue'),
      meta: {
        title: 'Settlements',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_settlements', 'credpal:can_delete_settlements'
    ]
  },
  'affiliate-statistics': {
    route: {
      name: 'affiliate-statistics',
      path: path('affiliate/statistics', admin),
      parent: 'app',
      component: () => import('@/views/app/affiliate/statistics/Statistics.vue'),
      meta: {
        title: 'Affiliate Statistics'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'transactions': {
    route: {
      name: 'transactions',
      path: `/:accountType(${accountTypes})/transactions`,
      parent: 'app',
      component: () => import( /* webpackChunkName: 'payments' */ '@/views/app/transactions/Index.vue'),
      meta: {
        title: 'Transactions',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'underwriters-statistics': {
    route: {
      name: 'underwriters-statistics',
      path: path('underwriters/statistics', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'underwriters-statistics' */ '@/views/app/underwriters/statistics/Index.vue'),
      meta: {
        title: 'Transactions',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'merchants': {
    route: {
      name: 'merchants',
      path: path('merchants', admin),
      parent: 'app',
      component: () => import(/* webpackChunkName: 'merchants' */ '@/views/app/merchant/Index.vue'),
      meta: {
        title: 'Merchants'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants']
  },
  'new-merchant': {
    route: {
      name: 'new-merchant',
      path: path('merchants/create', admin),
      parent: 'app',
      component: () => import(/* webpackChunkName: 'merchants-create' */ '@/views/app/merchant/Create.vue'),
      meta: {
        title: 'Create Merchant'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants']
  },
  'merchant-category': {
    route: {
      name: 'merchant-category',
      path: path('merchants/category', admin),
      parent: 'app',
      component: () => import(/* webpackChunkName: 'merchants-category' */ '@/views/app/merchant/Category.vue'),
      meta: {
        title: 'Category'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants']
  },
  'merchant-profile': {
    route: {
      name: 'merchant-profile',
      path: path('merchants/profile/:id', admin),
      parent: 'app',
      component: () => import(/* webpackChunkName: 'merchants-profile' */ '@/views/app/merchant/profile/Index.vue'),
      meta: {
        title: 'Merchant Profile'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants']
  },
  'merchant-transaction': {
    route: {
      name: 'merchant-transaction',
      path: path('merchants/transaction', admin),
      parent: 'app',
      component: () => import(/* webpackChunkName: 'merchants-transaction' */ '@/views/app/merchant/Transaction.vue'),
      meta: {
        title: 'Merchant Transaction'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants']
  },
  'merchant-incomplete-transaction': {
    route: {
      name: 'merchant-incomplete-transaction',
      path: path('merchants/incomplete-transaction', admin),
      parent: 'app',
      component: () => import(/* webpackChunkName: 'merchants-incomplete-transaction' */ '@/views/app/merchant/orders/IncompleteOrders.vue'),
      meta: {
        title: 'Merchant Incomplete Transaction'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants']
  },
  'merchant-pending': {
    route: {
      name: 'merchant-pending',
      path: path('merchants/awaiting-approval', admin),
      parent: 'app',
      component: () => import(/* webpackChunkName: 'merchants-pending' */ '@/views/app/merchant/AwaitingApproval.vue'),
      meta: {
        title: 'Merchant Awaiting Approval'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants']
  },
  'merchant-settlements': {
    route: {
      name: 'merchant-settlements',
      path: path('merchants/settlements', admin),
      parent: 'app',
      component: () => import(/* webpackChunkName: 'merchant-settlements' */ '@/views/app/merchant/settlements/Index.vue'),
      meta: {
        title: 'Merchant Settlements'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants']
  },
  'equity-contribution': {
    route: {
      name: 'equity-contribution',
      path: path('equity-contribution', admin),
      parent: 'app',
      component: () => import(/* webpackChunkName: 'equity-contribution' */ '@/views/app/merchant/EquityContributions.vue'),
      meta: {
        title: 'Equity Contribution'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants']
  },
  'cashbacks': {
    route: {
      name: 'cashbacks',
      path: path('cashbacks', admin),
      parent: 'app',
      component: () => import(/* webpackChunkName: 'cashbacks' */ '@/views/app/rewards/cashbacks/Index.vue'),
      meta: {
        title: 'Cashback'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants']
  },
  'wallets': {
    route: {
      name: 'wallets',
      path: path('wallets', admin, customer),
      parent: 'app',
      component: () => import(/* webpackChunkName: 'wallets' */ '@/views/app/rewards/Wallets.vue'),
      meta: {
        title: 'Reward Wallet'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'wallets-redemption': {
    route: {
      name: 'wallets-redemption',
      path: path('wallets/redemption', admin),
      parent: 'app',
      component: () => import(/* webpackChunkName: 'wallet-redemptions' */ '@/views/app/rewards/WalletsRedemption.vue'),
      meta: {
        title: 'Reward Wallet Redemption'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['credpal:can_view_merchants', 'credpal:can_create_merchants']
  },
  'redirect-user': {
    route: {
      name: 'redirect-user',
      path: '/a/m',
      parent: 'root',
      component: () => import( /* webpackChunkName: 'register' */ '@/views/app/affiliate/Redirect.vue'),
      meta: {
        title: 'Redirect',
      }
    },
    middlewares: [],
    permissions: ['*']
  },
  'upload-documents': {
    route: {
      name: 'upload-documents',
      path: '/upload-documents/token/:token',
      parent: 'root',
      component: () => import(/**/ '@/views/misc/upload-documents/Index.vue'),
      meta: {
        title: 'Upload Documents'
      }
    },
    middlewares: [],
    permissions: ['*']
  },
  'referred-users': {
    route: {
      name: 'referred-users',
      path: path('referred-users', admin),
      parent: 'app',
      component: () => import(/**/ '@/views/app/share-and-earn/super/Referral.vue'),
      meta: {
        title: 'Referred Users'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'users-referral-wallet': {
    route: {
      name: 'users-referral-wallet',
      path: path('users-referral-wallet', admin),
      parent: 'app',
      component: () => import(/**/ '@/views/app/share-and-earn/super/ReferralWallet.vue'),
      meta: {
        title: 'Referred Users'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'share-and-earn': {
    route: {
      name: 'share-and-earn',
      path: path('share-and-earn', admin, customer),
      parent: 'app',
      component: () => import(/**/ '@/views/app/share-and-earn/Index'),
      meta: {
        title: 'Share and Earn'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  "share-and-earn-redirect": {
    route: {
      name: 'share-and-earn-redirect',
      path: '/r/c/:referralCode',
      parent: 'root',
      component: () => import(/**/ '@/views/app/share-and-earn/Redirect.vue'),
      meta: {
        title: 'Redirect'
      }
    },
    middlewares: [],
    permissions: ['*']
  },
  'tangerine-personal-loans': {
    route: {
      name: 'tangerine-personal-loans',
      path: path('disbursement/personal/loans/tangerine', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'tangerine-personal-loans' */ '@/views/app/disbursement/lenders/loans/tangerineLife/Index.vue'),
      meta: {
        title: 'Tangerine Disbursement',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_loan_disbursements', 'credpal:can_disburse_loans', 'credpal:can_undisburse_loans',
      'company:can_view_loan_disbursements',
      'personal:can_view_loan_disbursements'
    ]
  },
  'tangerine-loan-repayments': {
    route: {
      name: 'tangerine-loan-repayments',
      path: path('repayments/personal/loans/tangerine', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'tangerine-loan-repayments' */ '@/views/app/repayments/lenders/loans/tangerineLife/Index.vue'),
      meta: {
        title: 'Tangerine Loan Repayments',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_loan_disbursements', 'credpal:can_disburse_loans', 'credpal:can_undisburse_loans',
      'company:can_view_loan_disbursements',
      'personal:can_view_loan_disbursements'
    ]
  },
  'tangerine-credit-card-repayments': {
    route: {
      name: 'tangerine-credit-card-repayments',
      path: path('repayments/personal/credit-card/tangerine', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'tangerine-loan-repayments' */ '@/views/app/repayments/lenders/credit-card/tangerineLife/Index.vue'),
      meta: {
        title: 'Tangerine Credit Card Repayments',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_loan_disbursements', 'credpal:can_disburse_loans', 'credpal:can_undisburse_loans',
      'company:can_view_loan_disbursements',
      'personal:can_view_loan_disbursements'
    ]
  },
  'tangerine-credit-card-disbursements': {
    route: {
      name: 'tangerine-credit-card-disbursements',
      path: path('disbursement/personal/credit-card/tangerine', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'tangerine-credit-card-disbursements' */ '@/views/app/disbursement/lenders/credit-card/tangerineLife/Index.vue'),
      meta: {
        title: 'Tangerine Repayments',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: [
      'credpal:can_view_loan_disbursements', 'credpal:can_disburse_loans', 'credpal:can_undisburse_loans',
      'company:can_view_loan_disbursements',
      'personal:can_view_loan_disbursements'
    ]
  },
  'rejected-docs': {
    route: {
      name: 'rejected-docs',
      path: path('doc-processor/rejected-docs', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'rejected-docs' */ '@/views/app/doc-processor/RejectedDocs.vue'),
      meta: {
        title: 'Users Rejected Docs'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'new-docs': {
    route: {
      name: 'new-docs',
      path: path('doc-processor/new-docs', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'new-docs' */ '@/views/app/doc-processor/NewDocs.vue'),
      meta: {
        title: 'Users New Uploaded Docs'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'doc-processor': {
    route: {
      name: 'doc-processor',
      path: path('doc-processor', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'doc-processor' */ '@/views/app/doc-processor/Index.vue'),
      meta: {
        title: 'Users Statement Doc Processor'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'loan-processor': {
    route: {
      name: 'loan-processor',
      path: path('doc-processor/loan', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'loan-processor' */ '@/views/app/doc-processor/loans/Index.vue'),
      meta: {
        title: 'Users Loan Processor'
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  'reconciliation': {
    route: {
      name: 'reconciliation',
      path: path('reconciliation', admin),
      parent: 'app',
      component: () => import( /* webpackChunkName: 'reconciliation' */ '@/views/app/reconciliation/Index.vue'),
      meta: {
        title: 'Reconciliation',
      }
    },
    middlewares: ['auth', 'password_changed', 'profile_updated', 'profile_approved', 'company_profile_updated', 'company_subscribed'],
    permissions: ['*']
  },
  ...require('./redirects').default
};
