import $ from 'jquery';
import Vue from 'vue';

Vue.mixin({
  directives: {
    fit: {
      inserted(element) {

        $(element).on('custom.update', () => {
          $(element).resizeText(element, $(element).data('fit'));
        });
        
      },
      update(element) {
        $(element).on('custom.update', () => {
          $(element).resizeText(element, $(element).data('fit'));
        });
      }
    },
    'scroll-to': {
      inserted(element, props, node) {
        const selector = props.value?.constructor === String ? props.value : 'body, html';
        const container = $(element).closest(selector);

        container.animate({
          scrollTop: container[0].scrollHeight
        });

        if (node.context.scrolled) {
          node.context.scrolled(...arguments);
        }
      }
    }
  },
  mounted() {
    $(this.$el).find('[data-fit]').each( (index, element) => {
      $(element).resizeText($(element).data('fit'));
    } );
  }
});