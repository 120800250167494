<template>
    <div>

        <!-- <div class="nav-title">Account</div> -->
        <ul class="nav">
            <li class="nav-item">
                <router-link :to="{ name: 'dashboard' }" class="flex flex-row items-center text-xs">
                    <ion-icon name="grid-outline" class="text-12 mr-4" />
                    <span>Dashboard</span>
                </router-link>
            </li>
        </ul>

        <template v-if="canAccess(['expenses', 'analytics'])">
            <div class="nav-title">Expense Management</div>
            <ul class="nav">
                <!-- <li class="nav-item" v-if="canAccess('expenses')">
                    <router-link :to="{ name: 'expenses' }" class="flex flex-row items-center text-xs">
                        <ion-icon name="cash-outline" class="text-12 mr-4" />
                        <span>Expense</span>
                    </router-link>
                </li> -->
                <!-- <li class="nav-item" v-if="canAccess('analytics')">
                    <router-link :to="{ name: 'analytics' }" class="flex flex-row items-center text-xs">
                        <ion-icon name="stats-chart" class="text-12 mr-4" />
                        <span>Analytics</span>
                    </router-link>
                </li> -->
            </ul>
        </template>
    
        <template v-if="canAccess(['staff', 'staff-new', 'admins', 'groups', 'groups-new'])">
            <div class="nav-title">Onboarding</div>
            <ul class="nav">
                <li class="nav-item" v-if="canAccess(['staff', 'staff-new', 'admins'])">
                    <a href="#!" class="flex flex-row items-center text-xs" @click.prevent="collapse($refs.staff)">
                        <ion-icon name="people-outline" class="text-12 mr-4" />
                        <span>Team</span>
                    </a>
                    <ul class="sub-nav collapse" ref="staff">
                        <li v-if="canAccess('staff')">
                            <router-link :to="{name: 'staff'}" class="flex flex-row items-center text-xs">
                                <span class="mr-3">-</span>
                                <span>All Staff</span>
                            </router-link>
                        </li>
                        <li v-if="canAccess('staff-new')">
                            <router-link :to="{name: 'staff-new'}" class="flex flex-row items-center text-xs">
                                <span class="mr-3">-</span>
                                <span>New Staff</span>
                            </router-link>
                        </li>
                        <li v-if="canAccess('admins')">
                            <router-link :to="{name: 'admins'}" class="flex flex-row items-center text-xs">
                                <span class="mr-3">-</span>
                                <span>Admins</span>
                            </router-link>
                        </li>
                    </ul>
                </li>
                <!-- <li class="nav-item" v-if="canAccess('staff-new')">
                    <router-link :to="{ name: 'staff-new' }" class="flex flex-row items-center text-xs">
                        <ion-icon name="person-add-outline" class="text-12 mr-4" />
                        <span>Add Employee</span>
                    </router-link>
                </li>
                <li class="nav-item" v-if="canAccess('staff')">
                    <router-link :to="{ name: 'staff' }" class="flex flex-row items-center text-xs">
                        <ion-icon name="people-outline" class="text-12 mr-4" />
                        <span>All Employees</span>
                    </router-link>
                </li> -->
                <li class="nav-item" v-if="canAccess(['groups', 'groups-new'])">
                    <a href="#!" class="flex flex-row items-center text-xs" @click.prevent="collapse($refs.groups)">
                        <ion-icon name="briefcase-outline" class="text-12 mr-4" />
                        <span>Departments & Units</span>
                    </a>
                    <ul class="sub-nav collapse" ref="groups">
                        <li v-if="canAccess('groups')">
                            <router-link :to="{name: 'groups'}" class="flex flex-row items-center text-xs">
                                <span class="mr-3">-</span>
                                <span>All Departments</span>
                            </router-link>
                        </li>
                        <li v-if="canAccess('groups-new')">
                            <router-link :to="{name: 'groups-new'}" class="flex flex-row items-center text-xs">
                                <span class="mr-3">-</span>
                                <span>New Department</span>
                            </router-link>
                        </li>
                    </ul>
                </li>
            </ul>
        </template>

        <template v-if="canAccess(['wallet', 'settlements', 'loans', 'statement', 'cards', 'beneficiaries', 'transfers'])">
            <div class="nav-title">PAYMENTS</div>
            <ul class="nav">
                <li class="nav-item" v-if="canAccess('wallet')">
                    <router-link :to="{name: 'wallet'}" class="flex flex-row items-center text-xs">
                        <ion-icon name="wallet-outline" class="text-12 mr-4" />
                        <span>Wallet</span>
                    </router-link>
                </li>
                <li class="nav-item" v-if="canAccess('cards')">
                    <router-link :to="{ name: 'cards' }" class="flex flex-row items-center text-xs">
                        <ion-icon name="card-outline" class="text-12 mr-4" />
                        <span>Cards</span>
                    </router-link>
                </li>
                <!-- <li class="nav-item" v-if="canAccess('settlements')">
                    <router-link :to="{ name: 'settlements-due' }" class="flex flex-row items-center text-xs">
                        <ion-icon name="reader-outline" class="text-12 mr-4" />
                        <span>Settlements</span>
                    </router-link>
                </li>
                <li class="nav-item" v-if="canAccess('loans')">
                    <router-link :to="{ name: 'loans' }" class="flex flex-row items-center text-xs">
                        <span class="text-12 mr-4" style="margin-left: 3px; padding-right: 2px">₦</span>
                        <ion-icon name="file-tray-full-outline" class="text-12 mr-4" />
                        <span>Employee Loans</span>
                    </router-link>
                </li> -->
                <li class="nav-item" v-if="canAccess('statement')">
                    <router-link :to="{ name: 'statement' }" class="flex flex-row items-center text-xs">
                        <ion-icon name="receipt-outline" class="text-12 mr-4" />
                        <span>Statement</span>
                    </router-link>
                </li>
                <li class="nav-item" v-if="canAccess('beneficiaries')">
                    <router-link :to="{ name: 'beneficiaries' }" class="flex flex-row items-center text-xs">
                        <ion-icon name="happy-outline" class="text-12 mr-4" />
                        <span>Beneficiaries</span>
                    </router-link>
                </li>
                <li class="nav-item" v-if="canAccess('transfers')">
                    <router-link :to="{ name: 'transfers' }" class="flex flex-row items-center text-xs">
                        <ion-icon name="repeat-outline" class="text-12 mr-4" />
                        <span>Transfers</span>
                    </router-link>
                </li>
                <!-- <li class="nav-item">
                    <router-link :to="{name: 'activities'}" class="flex flex-row items-center text-xs">
                        <ion-icon name="alarm-outline" class="text-12 mr-4" />
                        <span>All Activities</span>
                    </router-link>
                </li> -->
                <!-- <li class="nav-item" v-if="canAccess('repayments')">
                    <router-link :to="{ name: 'repayments' }" class="flex flex-row items-center text-xs">
                        <ion-icon name="cash-outline" class="text-12 mr-4" />
                        <span>Repayments</span>
                    </router-link>
                </li> -->
                <!-- <li class="nav-item" v-if="canAccess('payments')">
                    <router-link :to="{ name: 'payments' }" class="flex flex-row items-center text-xs">
                        <ion-icon name="document-text-outline" class="text-12 mr-4" />
                        <span>Payment History</span>
                    </router-link>
                </li> -->
            </ul>
        </template>

        <template v-if="canAccess(['settings-profile-company', 'settings-password'])">
            <div class="nav-title">Settings</div>
            <ul class="nav">
                <li class="nav-item" v-if="canAccess('permissions')">
                    <router-link :to="{ name: 'permissions' }" class="flex flex-row items-center text-xs">
                        <ion-icon name="key-outline" class="text-12 mr-4" />
                        <span>Permissions</span>
                    </router-link>
                </li>
                <li class="nav-item" v-if="canAccess('expense-policies')">
                    <router-link :to="{ name: 'expense-policies' }" class="flex flex-row items-center text-xs">
                        <ion-icon name="person-add-outline" class="text-12 mr-4" />
                        <span>Expense Policies</span>
                    </router-link>
                </li>
                <li class="nav-item" v-if="canAccess('settings-profile-company')">
                    <router-link :to="{ name: 'settings-profile-company' }" class="flex flex-row items-center text-xs">
                        <ion-icon name="settings-outline" class="text-12 mr-4" />
                        <span>Company Profile</span>
                    </router-link>
                </li>
                <li class="nav-item" v-if="canAccess('settings-password')">
                    <router-link :to="{ name: 'settings-password' }" class="flex flex-row items-center text-xs">
                        <ion-icon name="lock-closed-outline" class="text-12 mr-4" />
                        <span>Change Password</span>
                    </router-link>
                </li>
            </ul>

        </template>

    </div>
</template>

<script>
    export default {
        methods: {
            collapse(element) {
                window.collapse(element, 'toggle');
            }
        }
    }
</script>