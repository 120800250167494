<template>
	<div id="login-page">

		<div
			class="login-illlustration py-20 px-6 md:px-10 xl:px-20 hidden md:block"
			style="background: linear-gradient(218.26deg, #382FE0 0%, #3952D7 0.01%, #1E39A4 100.48%);"
		>
			<div class="xl:w-7/10">
				<div class="text-40 font-bold mb-5">
					CredPal cards are
					getting even smarter
				</div>

				<div class="font-thin opacity-75 mb-10">
					A card designed to help you meet your most important obligations when you need to and never keeps you in debt.
				</div>
			</div>

			<img :src="creditCards" class="max-w-full mx-auto" />

		</div>
		<div class="login-form">
			
			<div class="w-full px-6 md:px-10 xl:px-0 md:pb-20 xl:w-1/2 mx-auto">
				<!-- Router View -->
				<transition name="fade" mode="out-in">
					<router-view />
				</transition>
				<!-- Router View -->
			</div>
		</div>
		
	</div>
</template>

<script>
	export default {
		components: {
			// Navbar: require('@/views/auth/components/Navbar.vue').default
		},
		data() {
			return {
				creditCards: require('@/assets/credit-cards.svg'),
				logo: require('@/assets/logo-blue.svg')
			}
		}
	}
</script>