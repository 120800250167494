<template>
  <modal
    v-on="listeners"
    ref="modal"
    class-name="pt-20 px-20 w-full md:w-400px"
  >
    <vector
      :src="icon"
      class="flex justify-center mb-8"
      height="80"
      width="80"
    >
      <div class="bg-blue-200 h-80px rounded-full w-80px" />
    </vector>
    <div class="text-center">
      <div v-if="hasTitle" v-html="title" class="font-bold mb-5 text-24" />
      <div class="mb-16"  v-if="hasBody || hasSubBody">
        <div v-if="hasBody" v-html="body" class="font-light" />
        <div v-if="hasSubBody" v-html="subBody" class="font-light mt-5 text-gray-500 text-sm" />
      </div>
      <div class="flex flex-wrap items-center justify-center gap-4">
        <template v-for="(actionButton, buttonIndex) in actionButtons">
          <button
            :key="buttonIndex"
            type="button"
            :class="{
              'btn btn-md': true,
              'w-full': isSingleButton,
              [actionButton.className]: actionButton.className
            }"
            @click.prevent="click(actionButton.onClick)"
          >
            {{ actionButton.text }}
          </button>
        </template>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  props: {
    body: {
      type: String,
      default: null,
    },
    button: {
      type: [Object, String],
      default: null,
    },
    buttons: {
      type: Array,
    },
    isDismissible: {
      type: Boolean,
      default: true,
    },
    mountedManually: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    status: {
      type: String,
      default: 'alert',
    },
    subBody: {
      type: String,
      default: null,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      canDismiss: this.isDismissible
    }
  },
  computed: {
    actionButtons() {
      let buttons = [];

      if (this.buttons) {
        buttons = this.buttons.map(button => this.getButton(button))
      }

      if (this.button) {
        buttons.push(this.getButton(this.button));
      }

      return buttons;
    },
    hasTitle() {
      return this.title || this.$slots.title;
    },
    hasBody() {
      return this.body || this.$slots.body[0];
    },
    hasSubBody() {
      return this.subBody || this.$slots.subBody[0];
    },
    icon() {
      switch (this.status) {
        case 'error':
          return this.assets.icons.sm.errorCircle;
        case 'success':
          return this.assets.icons.sm.checkmarkCircle;
        case 'warning':
          return this.assets.icons.sm.clockCircleYellow;
      
        case 'alert':
        default:
          return this.assets.icons.sm.alertCircle;
      }
    },
    isSingleButton() {
      return this.actionButtons.length === 1;
    },
    listeners() {
      return {
        ...this.$listeners,
        close: () => {
          if (!this.isDismissible) {
            this.open();
          }
          this.$emit('close');
          this.onClose();
        }
      };
    }
  },
  mounted() {
    this.open();
  },
  methods: {
    click(callback) {
      if (this.canDismiss) {
        this.close();
      }

      if (callback) {
        callback(this);
      }
    },
    close() {
      this.$refs.modal.close();
      if (this.mountedManually) {
        setTimeout(() => {
          const element = this.$refs.modal.$el;
          element.parentElement.removeChild(element);
          this.$destroy();
        }, 1000);
      }
    },
    getButton(button) {
      switch (button?.constructor) {
        case Object:
          return {
            className: 'btn btn-blue',
            text: button.text,
            ...button
          };
        case String:
          return {
            className: 'btn btn-blue',
            text: button,
          };
        default:
          // statements_def
          break;
      }
    },
    open() {
      return this.$refs.modal.open();
    },
    setDismissable(status) {
      this.canDismiss = status;
    }
  },
};
</script>
