<template>
    <aside class="sidebar border-none" style="box-shadow: inset 5px 5px 24px rgba(39, 79, 237, 0.08);" :class="appMode">
        <div class="logo">
            <img :src="logo" alt="CredPal" class="mr-2">
            <div class="text-xs opacity-25" :class="accountType === ENV_VUE_APP_CUSTOMER_PATH ? 'text-black' : 'text-white'">{{ accountType === ENV_VUE_APP_CUSTOMER_PATH ? 'Personal' : accountType === ENV_VUE_APP_CORPORATE_PATH ? 'Business' : accountType === ENV_VUE_APP_ADMIN_PATH ? 'Admin' : '' }}</div>
        </div>

        <div class="flex-auto overflow-y-auto" id="index-sidebar">

            <component :is="appMode"></component>

        </div>

    </aside>
</template>

<script>
    export default {
        components: {
            super: require('./Super.vue').default,
            staff: require('./Staff.vue').default,
            company: require('./Company.vue').default,
        },
        data() {
            return {
                // logo: require('@/assets/logo.svg')
            }
        },
        computed: {
            logo() {
                if (this.accountType === this.ENV_VUE_APP_CUSTOMER_PATH) {
                    return require('@/assets/logo-blue.svg');
                }

                return require('@/assets/logo.svg');
            }
        },
        methods: {
            collapse(element) {
                window.collapse(element, 'toggle');
            }
        }
    }
</script>

<style lang="scss">
	#index-sidebar::-webkit-scrollbar{
		width: 4px;
		height: 0;
	}

	#index-sidebar::-webkit-scrollbar-thumb{
		background-color: rgb(95,126,250);
		border-radius: 10px;
	}
</style>