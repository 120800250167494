<template>
  <svg width="37" height="26" viewBox="0 0 37 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.000244141 12.9354C0.000244141 16.2921 1.37021 19.8107 3.56892 22.0655C7.87191 26.479 14.1396 27.2632 19.3249 23.991C19.7858 23.7009 20.5328 23.1237 20.895 22.7535C23.9958 19.576 23.4658 18.7511 23.4658 14.1947V8.6997C23.4658 6.9448 24.0127 5.89389 24.7766 4.90421C25.7477 3.64779 27.2332 3.20615 28.8907 3.20615C32.9175 3.20615 35.453 8.70116 32.2424 12.1672C31.112 13.3872 29.1585 14.0533 27.3869 13.5738C26.8132 13.4178 26.8118 13.2794 26.1226 13.2794C24.7216 13.2794 23.9154 15.6348 25.8027 16.3563C28.754 17.4844 31.625 16.6959 33.9661 14.75C39.2303 10.3759 36.6144 -0.000488281 28.5581 -0.000488281C27.2008 -0.000488281 25.6561 0.471762 24.7583 0.992112C22.7231 2.17274 21.1628 4.12587 20.654 6.59207C20.3439 8.09336 20.4764 14.2618 20.4778 16.2542C20.4793 18.6446 19.9986 19.8282 18.0889 21.1094C14.3229 23.6353 9.21932 23.4808 6.06079 20.1751C5.30252 19.3822 4.54424 18.477 4.05658 17.4407C2.51185 14.1554 2.80924 10.2782 4.82472 7.27858C5.04036 6.95646 5.11929 6.92585 5.3293 6.6562C5.54353 6.38218 5.58017 6.30493 5.8381 6.03673C7.08122 4.74242 9.51953 3.20615 11.955 3.20615C13.2714 3.20615 13.7873 3.14493 15.1023 3.50058C16.0875 3.76731 15.9578 4.00781 17.1572 4.00781C18.1706 4.00781 18.375 2.97148 18.375 2.17711C18.375 0.620433 14.3919 0.00242684 12.6189 0.00242684C10.492 0.00242684 9.00086 0.514031 7.50968 1.12912C6.76269 1.43813 6.14395 1.82875 5.51111 2.26748C5.17849 2.49777 4.93888 2.70475 4.63304 2.96274C4.45686 3.11141 4.40189 3.19449 4.23276 3.35045C3.2377 4.26725 2.39064 5.23653 1.73384 6.48858C1.50411 6.9273 1.3533 7.10659 1.15457 7.60653C0.521733 9.19527 0.00165357 10.7417 0.00165357 12.9383L0.000244141 12.9354Z" fill="#fff"/>
  </svg>
</template>

<script>
export default {
  name: 'LogoIcon'
};
</script>
