import Vue from "vue";
import moment from 'moment';

function numberToEnglish(n) {
  var string = n.toString(),
    units,
    tens,
    scales,
    start,
    end,
    chunks,
    chunksLen,
    chunk,
    ints,
    i,
    word,
    words,
    and = "and";

  /* Remove spaces and commas */
  string = string.replace(/[, ]/g, "");

  /* Is number zero? */
  if (parseInt(string) === 0) {
    return "zero";
  }

  /* Array of units as words */
  units = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];

  /* Array of tens as words */
  tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  /* Array of scales as words */
  scales = [
    "",
    "thousand",
    "million",
    "billion",
    "trillion",
    "quadrillion",
    "quintillion",
    "sextillion",
    "septillion",
    "octillion",
    "nonillion",
    "decillion",
    "undecillion",
    "duodecillion",
    "tredecillion",
    "quatttuor-decillion",
    "quindecillion",
    "sexdecillion",
    "septen-decillion",
    "octodecillion",
    "novemdecillion",
    "vigintillion",
    "centillion",
  ];

  /* Split user argument into 3 digit chunks from right to left */
  start = string.length;
  chunks = [];
  while (start > 0) {
    end = start;
    chunks.push(string.slice((start = Math.max(0, start - 3)), end));
  }

  /* Check if function has enough scale words to be able to stringify the user argument */
  chunksLen = chunks.length;
  if (chunksLen > scales.length) {
    return "";
  }

  /* Stringify each integer in each chunk */
  words = [];
  for (i = 0; i < chunksLen; i++) {
    chunk = parseInt(chunks[i]);
    if (chunk) {
      /* Split chunk into array of individual integers */
      ints = chunks[i]
        .split("")
        .reverse()
        .map(parseFloat);

      /* If tens integer is 1, i.e. 10, then add 10 to units integer */
      if (ints[1] === 1) {
        ints[0] += 10;
      }

      /* Add scale word if chunk is not zero and array item exists */
      if ((word = scales[i])) {
        words.push(word);
      }

      /* Add unit word if array item exists */
      if ((word = units[ints[0]])) {
        words.push(word);
      }

      /* Add tens word if array item exists */
      if ((word = tens[ints[1]])) {
        words.push(word);
      }

      /* Add 'and' string after units or tens integer if: */
      if (ints[0] || ints[1]) {
        /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
        if (ints[2] || (!i && chunksLen)) {
          words.push(and);
        }
      }

      /* Add hundreds word if array item exists */
      if ((word = units[ints[2]])) {
        words.push(word + " hundred");
      }
    }
  }

  return words.reverse().join(" ");
}

Vue.mixin({
  filters: {
    bankName(bank_code, banks = []) {
      if (!bank_code) {
        return null;
      }

      if (!banks) {
        return "Unknown Bank";
      }

      const bank = banks.find((b) => b.code == bank_code);

      return bank?.name || "Unknown Bank";
    },
    dateFormat: (date, format = 'D, M, Y h:ia') => typeof date != "string" ? date : new Date(date).format(format),
    fromNow: (date) => date && moment(date).fromNow(),
    cardNumber: (number) => number?.toString().replace(/(.{4})/g, "$1 "),
    currency(number, places = 2) {
      // if (!number) {
      //  return number;
      // }
      if (typeof number == "string") {
        number = number.replace(/,/g, "");
      }
      return Number(number)
        .toFixed(places)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    truncate(string, length = 20) {
      if (!string) {
        return string;
      }
      if (string.toString().length > length) {
        return `${string.toString().substring(0, length)}......`
      }
      return string;
    },
    lowerCase: (string) => string?.toLowerCase(),
    upperCase: (string) => string?.toUpperCase(),
    sentenceCase: (string) => string?.replace(/(^(\w)|\s(\w))/g, (x) => x.toUpperCase()),
    fromSlug: (string) => string?.fromSlug(),
    nearestWhole(number) {
      number = Number(number)
        .toFixed()
        .replace(",-");
      const length = number.length;
      var unit, places;

      if (length < 4) {
        return number;
      }

      if (length < 7) {
        unit = "K";
      } else if (length < 10) {
        unit = "M";
      } else if (length < 13) {
        unit = "B";
      } else if (length < 16) {
        unit = "T";
      } else if (length < 19) {
        unit = "!";
      }

      places = length % 3 == 0 ? 3 : length % 3;

      var regexp = new RegExp(`^\\d{${places}}`);

      return number.match(regexp)?.[0] + unit;
    },
    numberToWords: (number) => numberToEnglish(number),
    toPlural(string, condition) {
      var isPlural;

      switch (condition?.constructor) {
        case Number:
          isPlural = condition == 1 ? false : true;
          break;
        case Array:
          isPlural = condition.length == 1 ? false : true;
          break;
        case Boolean:
        default:
          isPlural = condition;
          break;
      }

      if (isPlural) {
        return `${string}s`;
      }

      return string;
    },
    percentage: (value) => `${parseFloat(value).toFixed(2)}%`,
    formatAmount(x = 0) {
      x = Number(x);
      return (
        "₦" +
        x
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      );
    },
  },
  listenForClick() {
    this.$el.addEventListener("click", (event) => {
      if (event.target.matches("[data-click]")) {
        const el = event.target;
        const pair = el.getAttribute("data-click");
        const match = pair.match(/^(\w+)\(([\s\S]*)\)/);
        if (!match) {
          throw new Error("Invalid Data-Click Value");
        }

        const method = match[1];
        const args = match[2].split(",").map((item) => item.trim());

        if (!this[method]) {
          throw new Error(`Undefined method ${method}`);
        }

        this[method].apply(this, args);
      }
    });
  },
});