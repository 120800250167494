import Vue from 'vue';

Vue.mixin({
  methods: {
    async pusherEvent(channel, event){
      switch (event) {
        case 'account-approval':
          channel.bind('account-approval', (data) => {
            console.log(data)
            location.reload();
            // this.$router.push({name: 'dashboard'});
            // this.$router.push({name: 'dashboard'}).catch((err) => {
            //   throw new Error(`Problem handling something: ${err}.`);
            // });
          })
          break;      
        default:
          break;
      }

    }
  }
});