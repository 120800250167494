import Cookies from "vue-cookies";

export default {
	/*
	===============================================================================================================
	Allow namespacing
	===============================================================================================================
	*/
	namespaced: true,
	/*
	===============================================================================================================
	Session Based Variables
	===============================================================================================================
	*/
	state: {
		/*
		------------------------------------------------------------------
		App Authenticated State
		------------------------------------------------------------------
		*/
		loggedIn: false,
		/*
		------------------------------------------------------------------
		Authenticated API Token
		------------------------------------------------------------------
		*/
		token: null,
		/*
		------------------------------------------------------------------
		Token Keyword For Storage
		------------------------------------------------------------------
		*/
		token_name: 'credpal-corporate-loan-session',
		/*
		------------------------------------------------------------------
		Authenticated User Account Information
		------------------------------------------------------------------
		*/
		user: null,
		/*
		------------------------------------------------------------------
		Authenticated User Account Information
		------------------------------------------------------------------
		*/
		corporate_card_count: 0,
		/*
		------------------------------------------------------------------
		Authenticated User Roles
		------------------------------------------------------------------
		*/
		roles: [],
		/*
		------------------------------------------------------------------
		Authenticated User Permissions
		------------------------------------------------------------------
		*/
		permissions: [],
		/*
		------------------------------------------------------------------
		General Configuration
		------------------------------------------------------------------
		*/
		configuration: {},
		/*
		------------------------------------------------------------------
		Authenticated User Account Statement
		------------------------------------------------------------------
		*/
		// personal_statement: {},
		/*
		------------------------------------------------------------------
		Authenticated User Permissions
		------------------------------------------------------------------
		*/
		staff_account_type: 'personal',
		/*
		------------------------------------------------------------------
		Thrid Party Authentication Keys
		------------------------------------------------------------------
		*/
		keys: null,
		/*
		------------------------------------------------------------------
		Store Expiry Date
		------------------------------------------------------------------
		*/
		expires_at: null,
		/*
		------------------------------------------------------------------
		Storage Timeout
		------------------------------------------------------------------
		*/
		timeout: '3h',
		/*
		------------------------------------------------------------------
		Store Ready State
		------------------------------------------------------------------
		*/
		ready: false
	},
	getters: {
		sessionExpired: state => {
			const time = state.expires_at;
			const now = (new Date()).getTime();

			return time ? now >= time : false;
		}
	},
	mutations: {
		free(state, callback = () => {}) {
			callback(state);
		},
		/*
		------------------------------------------------------------------
		Save User's Data in Session
		------------------------------------------------------------------
		*/
		setUser(state, { user, token, roles, permissions, keys, corporate_card_count, staff_account_type }) {
			const time = (new Date()).getTime() + 3600*1000;
			state.user = user;
			state.token = token;
			state.roles = roles;
			state.permissions = permissions;
			state.corporate_card_count = corporate_card_count;
			state.staff_account_type = staff_account_type;
			state.keys = keys;
			state.expires_at = time;
			state.loggedIn = true;
		},
		/*
		----------------------------------------------------------------------
		Update User's Data
		----------------------------------------------------------------------
		*/
		updateUser(state, user) {
			state.user = {
				...state.user,
				...user
			};
		},
		/*
		----------------------------------------------------------------------
		Update User's Data
		----------------------------------------------------------------------
		*/
		updateKeys(state, keys) {
			state.keys = keys;
		},
		/*
		----------------------------------------------------------------------
		Update User's Roles
		----------------------------------------------------------------------
		*/
		updateRoles(state, roles) {
			state.roles = roles;
		},
		/*
		----------------------------------------------------------------------
		Update User's Permissions
		----------------------------------------------------------------------
		*/
		updatePermissions(state, permissions) {
			state.permissions = permissions;
		},
		/*
		----------------------------------------------------------------------
		Update User's statement
		----------------------------------------------------------------------
		*/
		// updatePersonalStatement(state, personal_statement) {
		// 	state.personal_statement = personal_statement;
		// },

		/*
		----------------------------------------------------------------------
		Set General Configuration
		----------------------------------------------------------------------
		*/
		setConfiguration(state, configuration) {
			state.configuration = configuration;
		},

		/*
		----------------------------------------------------------------------
		Update User's Permissions
		----------------------------------------------------------------------
		*/
		updateCorporateCardCount(state, corporate_card_count) {
			state.corporate_card_count = corporate_card_count;
		},
		/*
		----------------------------------------------------------------------
		Update User's Permissions
		----------------------------------------------------------------------
		*/
		updateStaffAccountType(state, staff_account_type) {
			state.staff_account_type = staff_account_type;
		},		
		/*
		----------------------------------------------------------------------
		Remove User's Data from Session
		----------------------------------------------------------------------
		*/
		removeUser(state) {
			state.user = null;
			state.token = null;
			state.roles = [];
			state.permissions = [];
			state.keys = null;
			state.expires_at = null;
			state.loggedIn = null;
		},
		/*
		------------------------------------------------------------------
		Activate Session Module
		------------------------------------------------------------------
		*/
		ready(state) {
			state.ready = true;
		},
	},
	/*
	===============================================================================================================
	Session Based Actions
	===============================================================================================================
	*/
	actions: {
		/*
		------------------------------------------------------------------
		Activate Session Module
		------------------------------------------------------------------
		*/
		ready({ commit }) {
			commit('ready');
		},
		/*
		------------------------------------------------------------------
		Authenticate User
		------------------------------------------------------------------
		*/
		login({ commit, dispatch }, { user, token, roles, permissions, keys, corporate_card_count, staff_account_type = 'personal', remember}) {
			commit("setUser", { user, token, roles, permissions, keys, corporate_card_count, staff_account_type });
			dispatch("saveUser", { remember });
			// commit('ready');
		},
		/*
		------------------------------------------------------------------
		Authenticate User
		------------------------------------------------------------------
		*/
		updateUser({ commit, dispatch }, user) {
			commit("updateUser", user);
			dispatch("saveUser");
			// commit('ready');
		},
		/*
		------------------------------------------------------------------
		Remove Authentication for User
		------------------------------------------------------------------
		*/
		async logout({ dispatch, commit }) {
			commit('removeUser');
			dispatch('clearUser');
			dispatch('resources/clearAll', {}, { root: true });
			dispatch('modals/clearAll', {}, { root: true });

			// commit('ready');
		},
		/*
		------------------------------------------------------------------
		Load User's Data from Server
		------------------------------------------------------------------
		*/
		async loadUser({ state, dispatch, rootState }) {
			var data;

			switch (rootState.store_type) {
				case 'cookies':
					data = Cookies.get(state.token_name);
					break;
				default:
					data = localStorage.getItem(state.token_name);
					break;
			}

			if (data) {
				dispatch('login', { ...JSON.parse(atob(data)) });
			}

			// commit('ready');
		},
		/*
		------------------------------------------------------------------
		Save User's Data from Server
		------------------------------------------------------------------
		*/
		saveUser({ state, rootState }) {

			const newState = {
				// user: state.user,
				loggedIn: state.loggedIn,
				token: state.token,
				// corporate_card_count: state.corporate_card_count,
				staff_account_type: state.staff_account_type,
				// keys: state.keys,
				expires_at: state.expires_at
			};

			switch (rootState.store_type) {
				case 'cookies':
					Cookies.set(state.token_name, btoa(JSON.stringify(newState)), state.timeout);
					break;
				default:
					localStorage.setItem(state.token_name, btoa(JSON.stringify(newState)));
					break;
			}
		},
		/*
		----------------------------------------------------------------------
		Update User's Permissions
		----------------------------------------------------------------------
		*/
		updateStaffAccountType({ commit, dispatch }, staff_account_type) {
			commit('updateStaffAccountType', staff_account_type);
			dispatch('saveUser');
		},
		/*
		------------------------------------------------------------------
		Clear User's Data from Server
		------------------------------------------------------------------
		*/
		clearUser({ state, rootState }) {
			switch (rootState.store_type) {
				case 'cookies':
					Cookies.remove(state.token_name);
					break;
				default:
					localStorage.removeItem(state.token_name);
					break;
			}
		}
	}
}