<template>
  <div class="pre-loader bg-white flex items-center justify-center fixed inset-0 z-50" id="preloader">
    <div class="pre-loader-animation relative">
      <div
        class="w-100px h-100px border border-blue-200 rounded-full flex items-center justify-center"
        style="animation: spin 10s linear infinite; border-top-color: transparent;"
      >
        <div
          class="w-9/10 h-9/10 border border-blue-200 rounded-full flex items-center justify-center"
          style="animation: spin 10s linear infinite; border-top-color: transparent;"
        >
          <div
            class="w-9/10 h-9/10 border border-blue-200 rounded-full"
            style="animation: spin 10s linear infinite; border-top-color: transparent;"
          ></div>
        </div>
      </div>
      <div class="absolute mt-4 top-100 self-x-center whitespace-no-wrap text-center uppercase text-sm text-blue-400">
        Initializing App
      </div>
      <img :src="logoSm" alt="CredPal" class="w-40px h-40px absolute self-xy-center">
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppPreLoader',
  data() {
    return {
      logoSm: require('@/assets/logo-sm.svg')
    }
  }
}
</script>
